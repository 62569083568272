


























































import { Vue, Component, Prop, Watch } from '$/lib/vueExt';
import { formSelectOptions }           from '$/lib/utils';
import VueValidationMixin              from '$/lib/mixins/VueValidationMixin';
import { CountryLabels, CountryRegions, Country, getRegionLabel, validateCityProvince, getCityNames } from '$/lib/Address';

import { Address }  from '$/entities/Address';
import { Building } from '$/entities/Building';

export enum Size {
	xs = 'xs',
	sm = 'sm',
	md = 'md',
	lg = 'lg',
	xl = 'xl',
}

/**
 * Component that allows editing of street addresses.
 */
@Component
export default class AddressEditor extends Vue {

	@Prop()
	readonly value: Address;

	@Prop({ default : false })
	readonly required: boolean;

	@Prop({ default : Size.md })
	readonly size: Size;

	/**
	 * If false, hides the unit # field.
	 */
	@Prop({ default : false })
	readonly unitNumber: boolean;

	/**
	 * If false, hides the postal code field.
	 */
	@Prop({ default : true })
	readonly postalCode: boolean;

	/**
	 * If false, hides the country field.
	 */
	@Prop({ default : true })
	readonly country: boolean;

	/**
	 * If false, hides the county field. If true, only shows the county field for American addresses.
	 */
	@Prop({ default : false })
	readonly county: boolean;

	/**
	 * Number of columns to take up when in horizontal layout
	 */
	@Prop({ default : 1 })
	readonly numColumns: number;

	/**
	 * The breakpoint at which (and larger) to start laying out the address fields horizontally with labels on top.
	 * Can set to a value other than a breakpoint name (e.g. "never") to never use the horizontal layout
	 */
	@Prop({ default : Size.md })
	readonly horizontalSize: Size;

	/**
	 * In a vertical layout, this specifies how many columns (out of 12) to reserved for the left-positioned labels.
	 */
	@Prop({ default : 3 })
	readonly labelCols: number;

	/**
	 * In a vertical layout, the size at which to position labels on the left (instead of the top)
	 */
	@Prop({ default : 'sm' })
	readonly labelsLeftSize: Size;

	/**
	 * If true, attempts to validate the given address.
	 */
	@Prop({ default : false })
	readonly validateAddress: boolean;

	@Prop({ default : false })
	readonly readonly: boolean;

	/**
	 * If true, shows a link that opens up Google maps to this address
	 */
	@Prop({ default : false })
	readonly googleMapsLink: boolean;

	@Prop({ default : 'Number, Name & Direction' })
	readonly streetDescription: string;

	citySuggestions: string[] = [];
	countySuggestions: string[] = [];

	mounted() {
		for (const name in this.fields) {
			let ref = this.$refs[`${name}Input`] as any;
			ref     = Array.isArray(ref) ? ref[0] : ref;
			if (ref) {
				ref.validationField = name;
				ref.validationPath  = 'value';
				ref.registerInput?.(ref);
				ref.unwatch = this.$watch(`value.${name}`, _.debounce((value, oldValue) => {
					if (value !== oldValue) {
						void ref.isValid();
					}
				}, 200), { deep : true });
			}
		}

		if (this.validateAddress) {
			this.setupAddressValidation();
		}
	}

	get fields(): Dictionary<Field> {
		return {
			unitNumber : {
				label    : 'Unit #',
				required : false,
				showIf   : this.unitNumber,
				infotip  : {
					title       : "Don't have a Unit #?",
					placement   : 'bottom',
					description : 'If there is only one suite in this property, for example it is a house, then please enter 1 under Unit #.',
				},
			},
			street : {
				label       : 'Street',
				colSpan     : 1 + Number(!this.unitNumber) + Number(!this.postalCode),
				description : this.streetDescription,
			},
			city : {
				label : 'City/Town',
			},
			country : {
				label   : 'Country',
				showIf  : this.country,
				options : formSelectOptions(CountryLabels),
			},
			province : {
				label   : getRegionLabel(this.value?.country),
				options : formSelectOptions(CountryRegions[this.value?.country]),
			},
			postalCode : {
				label       : this.value?.isAmerican ? 'ZIP Code' : 'Postal Code',
				description : this.value?.isAmerican ? 'Format: nnnnn' : 'Format: A1B 2C3',
				showIf      : this.postalCode,
				pattern     : this.value?.isAmerican ? '^[0-9]{5}$' : '^[A-Za-z][0-9][A-Za-z][\\s\\-]*[0-9][A-Za-z][0-9]$',
				formatter   : (newValue: string) => {
					newValue = (newValue || '').trim().toLocaleUpperCase().replace(/\W*/g, '');
					switch (this.value?.country) {
						case Country.CA:
							// insert a space as the 4th character
							newValue = `${newValue.slice(0, 3)} ${newValue.slice(3)}`.slice(0, 7);
							break;
						case Country.US:
							newValue = newValue.slice(0, 5);
							break;
					}
					return newValue;
				},
			},
			county : {
				label    : 'County',
				required : this.value?.isAmerican,
				showIf   : this.county && this.value?.isAmerican,
				infotip  : {
					placement   : 'right',
					description : `FrontLobby uses the US Census Bureau Data API but is not endorsed or certified by the Census Bureau.
						We use this API to give suggestions for the county based on the other address fields.`,
				},
			},
		};
	}

	colProps(colSpan: number) {
		return {
			[this.horizontalSize] : (12 / this.numColumns)  * (Math.min(colSpan, this.numColumns) || 1),
		};
	}

	get labelColsSize() {
		return this.labelsLeftSize === Size.xs ? '' : `-${this.labelsLeftSize}`;
	}

	get formGroupProps() {
		return {
			'label-size'                          : this.size,
			[`label-cols${this.labelColsSize}`]   : this.labelCols,	// labels on the left for small layouts
			[`label-cols-${this.horizontalSize}`] : 12,
		};
	}

	get googleMapsUrl() {
		return `https://www.google.ca/maps/place/${this.value.street},+${this.value.city},+${this.value.country}+${this.value.postalCode}`;
	}

	private setupAddressValidation() {
		const addressEditor             = this; // eslint-disable-line @typescript-eslint/no-this-alias
		this.$refs.cityInput[0].isValid = async function() {
			const superResult = await VueValidationMixin.isValid(this);
			if (!superResult) {
				return superResult;
			}

			const value = addressEditor.value;

			// for now, only validates the city/province combination
			// SHOULDDO: add validation for full address
			if (value?.isCanadian && value?.city && value?.province) {
				let validationError = await validateCityProvince(value.city, value.province);
				if (validationError) {
					// suggest the city if there's only one in the list that matches
					const possibleCorrections = await getCityNames(value.city, value.province, { autocomplete : true });
					if (possibleCorrections.length === 1) {
						validationError += ` Did you mean "${possibleCorrections[0]}"?`;
					}
					this.setFeedback(validationError, 'warning');
					return true;
				}
			}

			this.setFeedback('');
			return true;
		};
	}

	@Watch('value.city')
	@Watch('value.province')
	@Watch('value.country')
	@Watch('value.street')
	@Watch('value.postalCode', { immediate : true })
	async onAddressChange() {
		if (this.unitNumber) {
			await this.value.autofixValidationErrors('street');
		}

		if (_.isNotEmpty(this.$refs.cityInput) && !(await this.$refs.cityInput?.[0].isValid())) {
			return;
		}

		this.citySuggestions = this.value?.isCanadian && this.value.city.length > 2 && this.value.province
			? await getCityNames(this.value.city, this.value.province, { autocomplete : true })
			: []
		;
		this.countySuggestions = await Building.findCountyName(this.value);
		if (this.countySuggestions.length === 1) {
			this.value.county = this.countySuggestions[0];
		}
	}

}

interface Field {
	label: string;
	required?: boolean;
	description?: string;
	placeholder?: string;
	options?: any[] | Record<any, any>;
	pattern?: string;
	showIf?: boolean;
	formatter?: (value: string) => string;
	colSpan?: number;
	infotip?: {
		title?: string;
		placement?: string;
		description: string;
	};
}
