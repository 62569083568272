import env              from '$/lib/env';
import { Country }      from '$/lib/Address';
import { BaseVerifier } from '$/lib/identityVerification/BaseVerifier';

import { VerificationType, VerificationResult } from '$/entities/lib/VerificationResults';

export abstract class PersonaVerifier extends BaseVerifier {

	abstract checkInquiryStatus(inquiryId: string): Promise<{ status: string; sessionToken: string }>;

	get personaEnvironment(): string {
		return env.config('persona.env', { required : true });
	}

	get personaTemplate(): string {
		return env.config('persona.templateID', { required : true });
	}

	/**
	 * Composes humanly-readable failure messages based on the verification result for a particular end user.
	 */
	getFailureReasons(verificationResult: VerificationResult, forSupport = false): string[] {
		const results = [];

		// missing PII
		const missingPII = [ PersonaCheckFailure.MissingFirstName, PersonaCheckFailure.MissingLastName, PersonaCheckFailure.MissingDateOfBirth ]
			.filter(reason => verificationResult.reasons.includes(reason));

		if (_.isNotEmpty(missingPII)) {
			let message = `The ${missingPII.map(reason => ({
				[PersonaCheckFailure.MissingFirstName]   : 'first name',
				[PersonaCheckFailure.MissingLastName]    : 'last name',
				[PersonaCheckFailure.MissingDateOfBirth] : 'date of birth',
			}[reason])).join(', ')} could not be extracted from your submitted government ID.`;

			if (!forSupport) {
				message += ' Please make sure your name and date of birth are clearly visible in the photo you submit.';
			}

			results.push(message);
		}

		// mismatched PII

		return results;
	}

	static get verificationType(): VerificationType {
		return VerificationType.Persona;
	}

	static get maxAttempts() {
		return 2;		// retries are already built-in to the Persona flow
	}

}

export default null as PersonaVerifier;

export const PersonaTestUsers = {
	ALEXANDER : {
		firstName      : 'ALEXANDER J',
		middleName     : '',
		lastName       : 'SAMPLE',
		unitNumber     : '',
		street         : '600 CALIFORNIA STREET',
		city           : 'SAN FRANCISCO',
		province       : 'CA',
		country        : Country.US,
		postalCode     : '94109',
		dateOfBirth    : new Date('1977-07-17 0:00'),
		nationalID     : '719029506',
		phoneNumber    : '+14168498284',
		assessmentPass : true,
	},
};

// SHOULDDO: Bring back when we have a way to display the failure reasons to the user
export enum PersonaCheckFailure {
	// user behaviour
	MissingFirstName    = 'missingFirstName',
	MissingLastName     = 'missingLastName',
	MissingDateOfBirth  = 'missingDateOfBirth',
	MismatchFirstName   = 'mismatchFirstName',
	MismatchLastName    = 'mismatchLastName',
	MismatchDateOfBirth = 'mismatchDateOfBirth',

	// fraud checks

}
