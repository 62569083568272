import Moment                from 'moment';
import { productFruits }     from 'product-fruits';
import env, { Environment }  from '$/lib/env';
import { LocalStorageCache } from '$/lib/localStorageExt';
import breakpoint            from '$/plugins/Breakpoint';

import { BaseRole }       from '$/entities/roles/BaseRole';
import { PackageFeature } from '$/entities/Package';
import { Lease }          from '$/entities/Lease';
import { Landlord }       from '$/entities/roles/Landlord';

export const ProductFruitsToken = {
	MyLeasesGuidedTour   : env.isEnvironment(Environment.PROD) ? '46657' : '43943',
	PullCreditReports    : '', // not implemented yet
	HelpBuildCredit      : env.isEnvironment(Environment.PROD) ? '46660' : '43949',
	RecoverDebtOwed      : env.isEnvironment(Environment.PROD) ? '46659' : '43880',
	EnsureRentPaidOnTime : env.isEnvironment(Environment.PROD) ? '46662' : '43947',
	ActivityTracker      : env.isEnvironment(Environment.PROD) ? '58901' : '54864',
};

export default class ProductFruits {

	static api;

	static triggerOnStart: string;

	static async identify(role: BaseRole) {
		// Don't use Product Fruits non-prod environments, unless specifically enabled, to avoid creating thousands of extra users
		// Only identify Landlords as well, which also helps cut down on the number of users created
		if ((env.isEnvironmentNot(Environment.PROD) && !new LocalStorageCache('preferences').get('productFruitsEnabled')) || !(role instanceof Landlord)) {
			return;
		}

		await role.loadRelation('org.package');

		productFruits.init(
			env.config('productFruits.workspaceCode'),
			'en',
			{
				username  : role.id,
				email     : role.user.email,
				firstname : role.user.firstName,
				lastname  : role.user.lastName,
				signUpAt  : Moment(role.createdOn).format('YYYY-MM-DDTHH:mm:ss'), // Product Fruits suggests this format
				role      : role.$class,
				props     : {
					env            : env.getEnvironment(), // mostly to differentiate dev/testing, since prod is in a different workspace
					country        : role.org.country,
					packageId      : role.org.package.id,
					packageName    : role.org.package.name,
					isPremium      : role.org.package.isPaid as any, // boolean is allowed, the type definition is incorrect
					isEnterprise   : role.org.additionalFeatures.includes(PackageFeature.YardiImport) as any,
					numLeases      : await Lease.count({ where : { org : role.org } }),
					hasMadePayment : (await role.org.getPaymentMethods()).length > 0 as any, // boolean is allowed
				},
			},
			{
				disableLocationChangeDetection : true, // needed for SPA - manually notify Product Fruits of page changes
			}
		);
		window.addEventListener('productfruits_ready', async () => {
			this.api = (window as any).productFruits.api;
			await this.trigger(this.triggerOnStart);
			this.triggerOnStart = null;
		});
	}

	static async trigger(token: string): Promise<boolean> {
		if (!breakpoint.lg || !token) {
			return false;
		}

		if (this.api) {
			this.api.tours.tryStartTour(token);
		}
		else {
			await this.identify(BaseRole.current);
			// if Product Fruits isn't ready yet, save the token to trigger once it is
			this.triggerOnStart = token;
		}

		return true;
	}

	static pageChanged() {
		productFruits.safeExec($productFruits => { // ensures Product Fruits is ready
			$productFruits.push([ 'pageChanged' ]);
		});
	}

}
