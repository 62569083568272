import typeorm                       from 'typeorm';
import type { EntityOptions }        from 'typeorm/decorator/options/EntityOptions';
import type { RelationMetadataArgs } from 'typeorm/metadata-args/RelationMetadataArgs';
import type { RelationType }         from 'typeorm/metadata/types/RelationTypes';

import { chain, hasMixin, isSubclass }               from '$/lib/utils';
import { DecimalTransformer, JSONTransformer }       from '$/lib/columnTransformers';
import ValidateOrig, { PossibleMessage, Validators } from '$/lib/Validate';
import type { ValidationRules }                      from '$/lib/Validate';

import { JSONable } from '$/entities/lib/JSONable';

// extra entity metadata for properties decorated with the @Relation decorator
export const extraEntityMetadata: RelationMetadataArgs[] = [];

// chaining typeorm directly isn't allowed since its properties are getters and chain() doesn't work on getters
const extendedDecorators = _.pick(typeorm, [ 'Column', 'JoinColumn', 'ManyToOne', 'Entity' ]);

/**
 * Override of the default typeorm.@Entity decorator that adds custom functionality
 */
chain(extendedDecorators, 'Entity', function(oldFunc, ...args) {
	const options   = normalizeDecoratorArgs('name', args);
	const decorator = oldFunc.call(this, options);
	return function(clazz: any) {
		if (options.common) {
			CommonEntity()(clazz);
		}
		return decorator.apply(this, arguments);   // eslint-disable-line prefer-rest-params
	};
});
export const Entity = extendedDecorators.Entity as EntityDecorator;

export const ChildEntity = typeorm.ChildEntity;

/**
 * Override of the default typeorm.@Column decorator that adds custom functionality.
 */
chain(extendedDecorators, 'Column', function(oldFunc, ...args) {
	const options = normalizeDecoratorArgs('type', args);
	return function(target: any, propertyKey: string) {
		const columnType = Reflect.getMetadata('design:type', target, propertyKey);

		if (!options.transformer) {
			// When column type is set to JSON automatically add a JSONTransformer if a transformer is not specified
			if (options.type === 'json') {
				options.transformer = JSONTransformer({ jsonable : hasMixin(columnType, JSONable) || isSubclass(JSONable, columnType, true) ? columnType : undefined });
			}

			// Replace typeorm's default behavior that transforms decimal types to string when loaded into an entity
			if (options.type === 'decimal') {
				options.transformer = DecimalTransformer;
			}
		}

		// SHOULDDO: implement the same logic for BooleanTransformer/BooleanNullableTransformer

		if (options.asExpression) {
			options.generatedType ||= 'VIRTUAL';	// default to VIRTUAL column
			options.select        ??= false;
			options.insert        ??= false;
			options.update        ??= false;
		}

		const decorator = oldFunc.call(this, options);
		return decorator.apply(this, arguments);   // eslint-disable-line prefer-rest-params
	};
});
export const Column = extendedDecorators.Column;

/**
 * Drop-in replacement for the typeorm.ManyToOne decorator.
 * This decorator adds another @Column with the same name as this field but with the Id suffix
 * to track the ID of the the related entity.
 */
chain(extendedDecorators, 'ManyToOne', function(oldFunc, ...args) {
	const decorator     = oldFunc.call(this, ...args);
	const typeormColumn = typeorm.Column({ type : 'varchar', nullable : true, length : 36 });

	return function(target: any, propertyKey: string) {
		// SHOULDDO: set permissions on this Id column to be the same as the original
		typeormColumn(target, `${propertyKey}Id`); // create the sibling Id-suffixed column
		return decorator.apply(this, arguments);   // eslint-disable-line prefer-rest-params
	};
});
export const ManyToOne = extendedDecorators.ManyToOne;

/**
 * Drop-in replacement for the typeorm.JoinColumn decorator.
 * This decorator adds another @Column with the same name as this field but with the Id suffix to track the ID of the the related entity.
 * For now, assumed to only be used with @OneToOne
 */
chain(extendedDecorators, 'JoinColumn', function(oldFunc, ...args) {
	const decorator = oldFunc.call(this, ...args);
	return function(target: any, propertyKey: string) {
		typeorm.Column({ type : 'varchar', nullable : true, length : 36 })(target, `${propertyKey}Id`); // create the sibling Id-suffixed column
		return decorator.apply(this, arguments);   // eslint-disable-line prefer-rest-params
	};
});
export const JoinColumn = extendedDecorators.JoinColumn;

export const OneToMany = typeorm.OneToMany;
export const OneToOne  = typeorm.OneToOne;

/**
 * Decorator to add a relation so that the Entity can be passed from client to server without needed to be stored in the DB
 * @param {string} type Entity name that is related without being stored
 */
// SHOULDDO: incorporate this as an option to ManyToOne, OneToOne, and Column decorators
export function Relation(type: string, { relationType = 'one-to-one' }: { relationType?: RelationType } = {}) {
	return function(clazz, propertyName: string) {
		extraEntityMetadata.push({
			target  : clazz.constructor,
			propertyName,
			relationType,
			type,
			isLazy  : undefined,
			options : undefined,
		});
	};
}

/**
 * Decorator that associates the API collection name for this entity.
 */
export function CollectionName(name: string) {
	return function(clazz) {
		Reflect.defineMetadata('collectionName', name, clazz);
	};
}

/**
 * Decorator which allows overriding of the property name with a different name.
 * Handy to delegate reading/writing of the property to a getter/setter pair.
 */
export function Alias(newName: string) {
	return function(clazz, property: string) {
		Reflect.defineMetadata('propertyAlias', newName, clazz.constructor, property);
	};
}

/**
 * Returns a normalized typeorm decorator options
 * Typeorm decorators accept [type: string, options: DecoratorOptions] OR [options: DecoratorOptions].
 * This function converts the first case to the second case which is easier to manipulate
 * @param firstParamName the name of the field to place the first param in the options param
 * @returns              a single options param to pass to the decorator
 */
function normalizeDecoratorArgs(firstParamName: string, args = []) {
	args[0] = args[0] || {};
	if (typeof args[0] === 'string') {
		args[0] = { [firstParamName] : args[0] };
	}
	if (args[1]) {
		args[0] = _.merge(args[0], args[1]);
	}
	return args[0];
}

export interface FindOneOptions<Entity = any> extends typeorm.FindOneOptions<Entity> {
	/**
	 * If true, calls .makeEditable on the returned entity/entities allowing it/them to be saved.
	 */
	editable?: boolean;

	/**
	 * If true, returns archived relations even if the original entity is not archived.
	 */
	withDeletedRelations?: boolean;
}

/**
 * Custom version of typeorm.FindManyOptions which replaces skip & take with offset & limit
 * We actually want skip/take in most cases (since that correctly returns the # of entities when relations are involved)
 * but limit/offset are widely used and we want to limit confusion between them.
 */
export interface FindManyOptions<Entity = any> extends Omit<typeorm.FindManyOptions<Entity>, 'skip' | 'take'>, FindOneOptions<Entity> {
	/**
	 * Limits the results of the find to only this many records.
	 */
	limit?: number;

	/**
	 * Skips this many initial records before returning remaining results.
	 */
	offset?: number;
}

/**
 * The default max number of entities that can be fetched with one client-side call to EntityClass.find()
 */
export const defaultFindLimit = 100;

/**
 * Decorator that marks an class as a common (server & client) entity.
 * This is valuable so that the server & client can tell which fields to omit from sending over the network.
 */
export function CommonEntity() {
	return function(clazz) {
		Reflect.defineMetadata('isCommonEntity', true, clazz);
	};
}

export interface UpdateOneOptions {
	/**
	 * If true, does not throw an errors (eg: if ID is falsy or entity not found)
	 */
	ignoreErrors?: boolean;
}

export interface ToObjectOptions {
	/**
	 * The prefix for each field in objects.
	 */
	fieldNamePrefix?: string;

	/**
	 * If false, don't output "base" fields like id, ver, $class.
	 * Defaults to true.
	 */
	mustBeBase?: boolean;

	/**
	 * If true, only output fields that have been edited
	 * Default to false.
	 */
	mustBeEdited?: boolean;

	/**
	 * If true, only output fields that are in the common class; if false, outputs all fields.
	 * Defaults to false.
	 */
	mustBeCommon?: boolean;
}

/**
 * HACK: Re-export the entire Entity Decorator type because EntityOptions
 * isn't exposed via typeorm and cannot be extended to add the `common` flag
 */
type EntityDecorator = (name?: string | EntityDecoratorOptions, options?: EntityDecoratorOptions) => ClassDecorator;
interface EntityDecoratorOptions extends EntityOptions{

	/**
	 * Indicates that this entity class is common to both the client & server.
	 * Required for technical reasons.
	 */
	// SHOULDDO: figure out how to be able to tell if a class is declared in the common dir and remove this
	common?: boolean;
}

/**
 * Adds a requiredRelation validation rule that is like required but specifically for relation fields
 * which can be "empty" when not loaded.
 * This rule checks for value of the related <field>Id field that holds id of the relation field.
 */
interface ValidationRulesExtended extends ValidationRules {
	requiredRelation?: PossibleMessage<boolean>;
}

export function Validate(rules: ValidationRulesExtended) {
	return ValidateOrig(rules);
}

(Validators as any).requiredRelation = function({ value: isRequired, message }) {
	if (typeof isRequired !== 'boolean') {
		throw new Error(`expected boolean value, instead got: ${typeof isRequired}`);
	}

	if (!isRequired) {
		return () => '';
	}

	message = message || 'must have a non-empty relation value';

	return function(relation, property: string) {
		const relationID = this[`${property}Id`];
		return isNil(relation) && isNil(relationID) ? message : '';
	};

	function isNil(value) {
		return value === undefined || value === null || value === '';
	}
};
