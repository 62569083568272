import { BaseVerifier }                        from '$/lib/identityVerification/BaseVerifier';
import type { Answers, Questionnaire, Result } from '$/lib/equifax/KountClient';

import { VerificationType } from '$/entities/lib/VerificationResults';
import type { User }        from '$/common/entities/User';		// common type on purpose to avoid build issue

/**
 * Implementation of identity verification using Equifax's Kount verification service (knowledge-based test)
 */
export abstract class KountVerifier extends BaseVerifier {

	abstract getQuestions(): Promise<Questionnaire | Result>

	abstract submitAnswers(answers: Answers)

	getAttempts(user: User) {
		return user.verificationResults.filter(result => result.type === VerificationType.Kount && !result.invalid).length;
	}

	/**
	 * @returns true if this user has reached or exceeded the maximum number of Kount attempts
	 */
	maxAttemptsReached(user: User) {
		return this.getAttempts(user) >= KountVerifier.maxAttempts;
	}

	static get maxAttempts() {
		return 2;
	}

}

export default null as KountVerifier;
