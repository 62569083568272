import Moment                from 'moment';
import Cookie                from 'js-cookie';
import env, { Environment }  from '$/lib/env';
import { LocalStorageCache } from '$/lib/localStorageExt';
import { loadScript }        from '$/lib/utils';

let loadPromise: Promise<any>;
const portalId = env.config('hubSpot.portalID');

export default class HubSpotTracker {

	static storage = new LocalStorageCache('hst');
	static utm = new LocalStorageCache('hs_utm'); // COULD DO: merge with this.storage
	static email: string;

	static captureUTMFieldsFromURL(): Record<UTM, string> {
		const currentURL  = new URL(window.location.href);
		const utmFields   = _.values(UTM);
		const result: any = {};
		if (utmFields.some(field => currentURL.searchParams.has(field))) {
			this.utm.clear();
			utmFields.forEach(field => {
				const value = currentURL.searchParams.get(field);
				if (value) {
					result[field] = value;
					this.utm.set(field, value, { expires : Moment().add(1, 'day').toDate() });
				}
			});
		}
		return result;
	}

	static load(): Promise<void> {
		if (!loadPromise) {
			loadPromise = loadTracker();
		}
		return loadPromise;
	}

	static recordRouteChange(path: string, query: string | Dictionary<string> = {}) {
		const searchParams = new URLSearchParams(query);
		for (const key of searchParams.keys()) {
			if (!_.values(UTM).includes(key as UTM)) {
				searchParams.delete(key);
			}
		}

		const search = searchParams.toString();

		addCommand('setPath', `${path}${search ? `?${search}` : ''}`);
		addCommand('trackPageView');
	}

	static identify(email: string) {
		this.email = email;
		addCommand('identify', { email }); // Identify call looks like this - https://track.hubspot.com/__ptq.gif
	}

	static resetIdentity() {
		this.email = null;
		Cookie.remove('hubspotutk');
		Cookie.remove('__hstc');
		Cookie.remove('__hsrc');
	}

	/**
	 * @deprecated replaced with custom behavioral events
	 */
	static trackBehavioralEvent(name: string) {
		addCommand('trackEvent', { id : name });
	}

	static trackCustomBehavioralEvent(name: string, details: Dictionary<string>) {
		const event: CustomBehavioralEvent = {
			name,
			properties : _.compactObject({
				/* eslint-disable @typescript-eslint/naming-convention */
				hs_utm_campaign : this.utm.get(UTM.Campaign),
				hs_utm_source   : this.utm.get(UTM.Source),
				hs_utm_medium   : this.utm.get(UTM.Medium),
				hs_utm_content  : this.utm.get(UTM.Content),
				hs_utm_term     : this.utm.get(UTM.Term),
				/* eslint-enable @typescript-eslint/naming-convention */
				...details,
			}),
		};
		addCommand('trackCustomBehavioralEvent', _.compactObject(event));
	}

}

function addCommand(name: string, details?) {
	const HSQueue = (window as any)._hsq = (window as any)._hsq || [];
	HSQueue.push(_.compact([ name, details ]));
}

async function loadTracker() {
	if (!portalId || (env.isEnvironmentNot(Environment.PROD) && !HubSpotTracker.storage.get('isEnabled'))) {
		return;
	}
	try {
		await loadScript(`https://js.hs-scripts.com/${portalId}.js`);
	}
	catch (e) {
		console.error('Failed to load hubspot tracker');
	}
}

interface CustomBehavioralEvent {
	name: string;
	properties?: {
		/* eslint-disable @typescript-eslint/naming-convention */
		hs_utm_campaign: string;
		hs_utm_source: string;
		hs_utm_medium: string;
		hs_utm_content: string;
		hs_utm_term: string;
		/* eslint-enable @typescript-eslint/naming-convention */
	} & Dictionary<string>; // for other custom properties that are defined on HubSpot's end
}

enum UTM {
	Campaign = 'utm_campaign',
	Source   = 'utm_source',
	Medium   = 'utm_medium',
	Term     = 'utm_term',
	Content  = 'utm_content',
}
