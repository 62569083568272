































import { BFormSelect as BFormSelectOriginal } from 'bootstrap-vue';
import { Component, Prop }                    from '$/lib/vueExt';
import VueValidationMixin                     from '$/lib/mixins/VueValidationMixin';

/**
 * Overwrite BFormSelect to add support for mutating certain props
 */
@Component({ components : { BFormSelectOriginal } })
export default class FormSelect extends VueValidationMixin {

	@Prop({ default : false })
	readonly plaintext: boolean;

	/**
	 * The text to show when no option is selected (much like the same named prop for <input> elements)
	 */
	@Prop({ default : '--select--' })
	readonly placeholder: string | { value: any; label: string};

	/**
	 * If true, does not create a placeholder option.
	 */
	@Prop({ default : false })
	readonly noPlaceholder: boolean;

	@Prop({ default : 'md' })
	readonly size: 'sm' | 'md' | 'lg';

	get plaintextValue() {
		const options = this.$attrs.options as unknown as { text: string; value: string }[];
		return options?.find(option => option.value === this.$attrs.value)?.text || '';
	}

	get shouldShowPlaceholder() {
		// consider an option that corresponds to '' to be the placeholder so don't create another one if it exists
		return !this.noPlaceholder && !_.some(this.$attrs.options, option => option === '' || (option as any).value === '');
	}

}
