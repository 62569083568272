/**
 * Various misc utilities and helper functions that don't have any better place to go.
 */
import Vue                            from 'vue';
import { Debounce as DebounceCommon } from '$/common/lib/utils';
import { BvTableField, BvTableFieldArray } from 'bootstrap-vue';

export * from '$/common/lib/utils';

export type TableField = BvTableField & {
	width?: string | number;
	key?: string;
	if?: boolean; 	// if false, omits the given field from the table (useful for conditional fields)
	click?: (...args: any[]) => any;
}

/**
 * Formats an object of table fields where the each object's key is the field key.
 * @returns an array suitable for passing to the fields prop of the Table Component.
 */
export function tableFields(fields: Record<string, string | TableField>): BvTableFieldArray {
	return _.compact(_.map(fields, (value, key) => {
		if (typeof value === 'string') {
			value = { key, label : value } as BvTableField;
		}

		if (_.isNil(value) || value.hasOwnProperty('if') && !value.if) {
			return null;
		}

		if (value.width) {
			_.set(value, 'thStyle.width', value.width);
			delete value.width;
		}

		value.key = key;

		return value;
	})) as BvTableFieldArray;
}

/**
 * Formats an object of form-select options where each object's key is the option value.
 * @returns an array suitable for passing to the options prop of the Form Select Component
 */
export interface FormSelectOptions { orderBy?: string | false; invert?: boolean; startCase?: boolean }
export function formSelectOptions(options, { orderBy = 'text', invert = false, startCase = false }: FormSelectOptions  = {}) {
	options = invert ? _.invert(options) : options;
	options = _.map(options, (result: any, key) => {
		result       = _.isString(result) ? { text : startCase ? _.startCase(result) : result } : result;
		result.value = key;
		return result;
	});

	return (orderBy ? _.orderBy(options, orderBy) : options) as { text: string; value: string}[];
}

/**
 * Causes the browser to "download" a file.
 * @param {string} filename the name of the download file
 * @param {string} data the contents of the file
 */
export function downloadData(filename: string, data: string | ArrayBufferLike, type?: string) {
	// create download in the browser
	const url       = window.URL.createObjectURL(new Blob([ data ], { type }));
	const a         = document.createElement('a');
	a.style.display = 'none';
	a.href          = url;
	a.download      = filename;
	document.body.appendChild(a);
	a.click();
	window.URL.revokeObjectURL(url);
	document.body.removeChild(a);
}

/**
 * Allows calling a method within Vue through the special query parameter `action` once everything is rendered
 * @param name alternative name to use to trigger the action (defaults to the name of the decorated function)
 * @example
 * ```
 * @QuickAction() someMethod() { }
 * https://example.com/route?action=someMethod
 * ```
 */
export function QuickAction(name?: string) {
	return function(target, propertyKey) {
		name             = name || propertyKey;
		const oldMounted = target.mounted;
		target.mounted   = function() {
			oldMounted?.call(this);

			// Check and run actions after the entire view has been rendered
			this.$nextTick(function() {
				if (this.$route?.query?.action === name) {
					this[propertyKey].call(this);
				}
			});
		};
	};
}

export async function printElement(elementToPrint: Element) {
	try {
		let element = elementToPrint;
		while (element) {
			element.classList.add('print-element');
			element = element.parentElement;
		}
		window.print();
	}
	finally {
		let element = elementToPrint;
		while (element) {
			element.classList.remove('print-element');
			element = element.parentElement;
		}
	}
}

export async function loadScript(src: string): Promise<Event> {
	return new Promise((resolve, reject) => {
		const script   = document.createElement('script');
		script.async   = true;
		script.src     = src;
		script.onload  = resolve;
		script.onerror = reject;

		document.head.appendChild(script);
	});
}

/**
 * Same as common/lib/utils:Debounce() but adds support for Vue classes since instances of Vue classes are not actually instances
 * of the class that the Debounce() decorator is used in.
 */
export function Debounce(wait = 500) {
	return DebounceCommon(wait, { constructors : [ Vue ] });
}
