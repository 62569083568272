/**
 * Would ideally be better in File.ts, but that causes circular dependencies.
 */

export enum DocumentType {
	BuildingOwnership            = 'buildingOwnership', // role verification, also attaches to building and needed for reporting for small landlords
	UserPhotoID                  = 'userPhotoID', // for user verification
	PMBusinessCard               = 'pmBusinessCard', // role verification
	PMManagementAgreement        = 'pmManagementAgreement', // role verification
	OrgCompanyProof              = 'orgCompanyProof', // role verification
	LeaseAgreement               = 'leaseAgreement', // needed for reporting for small landlords, also for collections
	CollectionsRentalLedger      = 'collectionsRentalLedger', // needed for collections
	CollectionsRentalApplication = 'collectionsRentalApplication', // needed for collections
	CollectionsOrderJudgement    = 'collectionsOrderJudgement', // needed for collections
	CollectionsTenantID          = 'collectionsTenantID', // needed for collections
	Unknown                      = 'unknown' // some existing files have weird names and can't be categorized properly. SHOULDDO: fix these
}
