
























import { Vue, Component, Prop, Watch } from '$/lib/vueExt';

@Component
export default class ProgressModal extends Vue {

	/**
	 * The progress of the progress bar.  Must be between 0.0 and 1.0
	 */
	@Prop()
	readonly progress: number;

	/**
	 * A message to show (can also use the #message slot)
	 */
	@Prop({ default : '' })
	message: string;

	/**
	 * If true, adds a time estimate to the progress bar.
	 */
	@Prop({ default : false })
	readonly timeEstimate: boolean;

	startTime: Date = null;

	get normalizeProgress() {
		return Math.min(1, this.progress);
	}

	get secondsUntilCompletion() {
		if (!this.startTime || this.normalizeProgress === 0) {
			return undefined;
		}
		const elapsedSeconds = (Date.now() - this.startTime.valueOf()) / 1000;
		return Math.round(elapsedSeconds / this.normalizeProgress - elapsedSeconds);
	}

	@Watch('progress')
	onProgressChange() {
		if (this.progress >= 0 && this.startTime === null) {
			this.startTime = new Date();
		}
		else if ((this.progress < 0 || _.isNil(this.progress)) && this.startTime !== null) {
			this.startTime = null;
		}
	}

}
