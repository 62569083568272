import Moment                             from 'moment';
import { Unique, BeforeUpdate, LessThan } from '$/lib/typeormExt';
import Validate                           from '$/lib/Validate';
import { DayTransformer, BooleanTransformer } from '$/lib/columnTransformers';

import { ManyToOne, Alias, CommonEntity, Column } from '$/entities/BaseEntity';
import { BaseOrgEntity }                          from '$/entities/Organization';
import { reportSubmissionDay, type Lease }        from '$/entities/Lease';
import { RolePermission }                         from '$/entities/roles/RolePermission';
import Permissions, { Context }                   from '$/entities/lib/Permissions';

export enum LeaseBalanceStatus {
	Unset      = '',                // no amount has been specified
	PaidOnTime = 'paidOnTime',
	PaidLate   = 'paidLate',		// paid in full but not on-time
	Owing      = 'owing',
//	Inactive   = 'inactive', 		// not actually a status being used at this time
}

let samples: LeaseBalance[];

/**
 * The balance of total monies owed on the Lease in a particular month.
 */
@CommonEntity()
@Unique([ 'lease', 'month' ])
export class LeaseBalance extends BaseOrgEntity {

	@ManyToOne('Lease', { onDelete : 'CASCADE' })
	lease: Lease = undefined;

	/**
	 * The month of the lease status.
	 */
	@Column({ type : 'date', transformer : DayTransformer })
	@Validate({ required : true, date : true })
	month: Date = null;

	/**
	 * If true, this month's payment was late.
	 */
	@Column({ type : 'boolean', transformer : BooleanTransformer })
	latePayment: boolean = false;

	@Column({
		// eslint-disable-next-line max-len
		asExpression : `CASE WHEN amount = 0 AND latePayment THEN "${LeaseBalanceStatus.PaidLate}" WHEN amount > 0 THEN "${LeaseBalanceStatus.Owing}" WHEN amount IS NULL THEN "${LeaseBalanceStatus.Unset}" ELSE "${LeaseBalanceStatus.PaidOnTime}" END`,
		nullable     : false,
	})
	get status(): LeaseBalanceStatus {
		if (this.amountValue === 0 && this.latePayment) {
			return LeaseBalanceStatus.PaidLate;
		}
		if (this.amountValue > 0) {
			return LeaseBalanceStatus.Owing;
		}
		if (this.amountValue === undefined || this.amountValue === null) {
			return LeaseBalanceStatus.Unset;
		}
		return LeaseBalanceStatus.PaidOnTime;
	}
	set status(newStatus: LeaseBalanceStatus) {
		if (newStatus === LeaseBalanceStatus.PaidOnTime) {
			this.latePayment = false;
			this.amountValue = 0;
		}
		else if (newStatus === LeaseBalanceStatus.PaidLate) {
			this.latePayment = true;
			this.amountValue = 0;
		}
		else if (newStatus === LeaseBalanceStatus.Owing) {
			this.latePayment = false;
		}
		else if (newStatus === LeaseBalanceStatus.Unset) {
			this.latePayment = false;
			this.amountValue = undefined;
		}
	}

	/**
	 * The total amount owing as of this.month.
	 * Cannot be negative.
	 */
	@Alias('amount')
	@Column('decimal', { name : 'amount', precision : 10, scale : 2, nullable : true })
	@Permissions({ write : canUpdateCollectionsStatus })
	@Validate({ required : true, number : true, min : 0 })
	private amountValue: number = 0;

	@Permissions({ write : canUpdateCollectionsStatus })
	@Validate({ required : true, number : true, min : 0 })	// SHOULDDO: figure out how to DRY the validation rules between here and amountValue
	get amount(): number {
		return this.amountValue;
	}
	set amount(newAmount) {
		if (typeof newAmount === 'string') {
			const amount = Number.parseFloat(newAmount);
			if (!isNaN(amount)) {
				newAmount = amount;
			}
		}
		if (newAmount === 0) {
			if (this.status === LeaseBalanceStatus.Owing) {
				this.status = LeaseBalanceStatus.PaidOnTime;
			}
			this.amountValue = 0;
		}
		else if (newAmount === undefined || newAmount === null) {
			this.amountValue = undefined;
			this.status      = LeaseBalanceStatus.Unset;
		}
		else if (newAmount > 0) {
			this.amountValue = newAmount;
			if ([ LeaseBalanceStatus.PaidOnTime, LeaseBalanceStatus.PaidLate ].includes(this.status)) {
				this.status = LeaseBalanceStatus.Owing;
			}
		}
		else {
			this.amountValue = newAmount;
		}
	}

	/**
	 * The amount of rent on the lease this month (a copy of this.lease.monthlyRent at the time of this.month)
	 * If null, the precise monthlyAmount is unknown.
	 */
	@Column({ nullable : true, default : null })
	monthlyAmount: number = null;

	/**
	 * The amount reported to credit bureaus for this balance's month.
	 */
	@Column('decimal', { nullable : true, default : null, precision : 10, scale : 2 })
	amountWithThreshold: number = null;

	constructor(month?: Date, amount?: number, lease?: Lease, status?: LeaseBalanceStatus) {
		super();
		if (month) {
			this.month = Moment(month).startOf('month').add(12, 'hours').toDate();
		}
		this.amount = amount;
		if (lease !== undefined) {
			this.lease         = lease;
			this.orgId         = lease.orgId;
			this.org           = lease.org;
			this.monthlyAmount = this.lease.wasCurrentOn(this.month) ? this.lease.monthlyAmount : 0;
		}
		if (status) {
			this.status = status;
		}
	}

	// SHOULD DO: move to validation autofix
	@BeforeUpdate()
	formatMonth() {
		this.month = Moment(this.month).startOf('month').toDate();
	}

	/**
	 * Returns this.month in <year-month> string format.
	 */
	get monthLabel(): string {
		return this.month instanceof Date ? Moment(this.month).format('Y - MMMM') : '';
	}

	/**
	 * The amount reported as owing on the lease for this.month.
	 * For Current Leases, if the amount in under the reporting threshold, report a balance of $0.
	 * @returns the amount to be reported to credit bureaus
	 */
	async getReportingAmount() {
		// SHOULDDO: track timestamps of changes to the thresholds so that any future changes do not affect this balance
		if (_.isNil(this.amountWithThreshold) || Moment().isBefore(Moment(this.month).add(1, 'month').date(reportSubmissionDay), 'day')) {
			await this.loadRelation('lease.org', { required : true });
			this.amountWithThreshold = this.lease.wasCurrentOn(this.month) && this.amount < this.lease.org.limits.rentReportingThreshold ? 0 : this.amount;
		}

		return this.amountWithThreshold;
	}

	/**
	 * @param {Number} [previousAmountOwed] the amount reported previously (defaults to the reportingAmount from last month's leaseBalance)
	 * @returns {number} the amount actually paid this month
	 */
	async getPaymentAmount({ previousAmountOwed }: { previousAmountOwed?: number} = {}): Promise<number> {
		await this.loadRelation('lease', { required : true });

		if (previousAmountOwed === undefined) {
			const prevMonth    = Moment(this.month).subtract(1, 'month');
			const prevBalance  = await (this.constructor as typeof LeaseBalance).getFor(this.lease, prevMonth, { create : true });
			previousAmountOwed = await prevBalance.getReportingAmount();
		}

		let rentAmount = this.monthlyAmount;
		if (_.isNil(rentAmount)) {
			// compute best guess at to what the rent amount could have been
			rentAmount = this.lease.wasCurrentOn(this.month) ? this.lease.monthlyAmount : 0;
		}

		const amount = await this.getReportingAmount();

		// The Math.max will return NaN if one of the params is undefined, so we have an explicit null/undefined check
		return _.isNil(previousAmountOwed) || _.isNil(amount) ? null : Math.max(0, previousAmountOwed - amount + rentAmount);
	}

	isEditableByLandlord({ isBeingImported = false } = {}): boolean {
		if (!this.lease) {
			throw new Error('lease is not loaded');
		}

		if (!this.lease.leaseBalances) {
			throw new Error('lease.leaseBalances is not loaded');
		}

		if (Moment().isBefore(this.month, 'month')) {
			return false; // cannot be in the future
		}

		if (this.isNew && this.lease.isFormer) {
			return true; // former leases' balance can be edited regardless of month
		}

		return Moment(this.month).isSameOrAfter(this.lease.getOldestEditableMonth(this.lease.leaseBalances, { isBeingImported }), 'month');
	}

	// #region STATICS

	/**
	 * Gets the LeaseBalance entity for the given lease and month.
	 * @param {Date|string} [month] the month for which to fetch a LeaseBalance; if falsy, the most recent LeaseBalance is searched
	 * @param {Object}  [options]
	 * @param {Boolean} [options.create=false] if true, creates a LeaseBalance for the month if none found
	 * @param {Boolean} [options.editable=false] if true, calls makeEditable on the leaseBalance
	 */
	static async getFor<T extends typeof LeaseBalance>(
		this: T,
		lease: Lease,
		month?: Date | Moment.Moment,
		{ create = false, editable = false } = {}
	): Promise<InstanceType<T>> {
		let leaseBalance: LeaseBalance;
		let lastLeaseBalance: LeaseBalance;
		const monthAsMoment = Moment(month ?? new Date()).startOf('month').add(12, 'hours');
		month               = month instanceof Date ? month : month?.toDate();

		if (!lease.isSample) {
			if (month instanceof Date) {
				// search through the loaded balances first...
				leaseBalance = (lease.leaseBalances as unknown as LeaseBalance[])?.find(balance => monthAsMoment.isSame(balance.month, 'month'));
				// ...then search the DB
				leaseBalance ??= await this.findOne({ where : { lease, month : monthAsMoment.format('YYYY-MM-01') } });
			}

			if (!month || (!leaseBalance && create)) {
				const where             = { lease, month : LessThan(monthAsMoment.format('YYYY-MM-01')) };
				const lastBalanceFromDB = await this.findOne({ where, order : { month : 'DESC' } });

				const lastLoadedBalance = _.orderBy(lease.leaseBalances ?? [], 'month', 'desc')
					.find(leaseBalance => monthAsMoment.isSameOrAfter(leaseBalance.month, 'month'));

				if (lastBalanceFromDB && lastLoadedBalance) {
					lastLeaseBalance = Moment(lastBalanceFromDB.month).isAfter(lastLoadedBalance.month, 'month')
						? lastBalanceFromDB
						: lastLoadedBalance as unknown as LeaseBalance;
				}
				else {
					lastLeaseBalance = (lastLoadedBalance ?? lastBalanceFromDB) as unknown as LeaseBalance;
				}

				if (lastLeaseBalance && !month) {
					leaseBalance = lastLeaseBalance;
				}
			}
		}

		if (!leaseBalance && create) {
			let newAmount = 0;

			if (!lease.wasCurrentOn(month) && lastLeaseBalance) {
				// For former leases, carry the last balance forward
				newAmount = lastLeaseBalance.amount;
			}
			else if (lease.wasCurrentOn(month) && lastLeaseBalance?.amount > 0 || (lastLeaseBalance && _.isNil(lastLeaseBalance.amount))) {
				// For current leases that owed the previous month, create the balance without an amount to force the LL to enter it
				newAmount = undefined;
			}

			leaseBalance = new this(monthAsMoment.toDate(), newAmount, lease);
		}

		if (leaseBalance) {
			leaseBalance.lease = lease;
			leaseBalance.org   = lease.org;
			if (editable) {
				leaseBalance.makeEditable();
			}
		}

		return leaseBalance as InstanceType<T>;
	}

	/**
	 * Returns sample data.
	 */
	static getSamples() {
		if (!samples) {
			samples = [
				new this(Moment().subtract(2, 'months').toDate(), 0, undefined, LeaseBalanceStatus.PaidOnTime),
				new this(Moment().subtract(1, 'months').toDate(), 1300, undefined, LeaseBalanceStatus.Owing),
				new this(new Date(), 1300, undefined, LeaseBalanceStatus.Owing),
			];
		}
		return samples;
	}

	// #endregion

}

async function canUpdateCollectionsStatus(context: Context, leaseBalance: LeaseBalance) {
	await leaseBalance.loadRelation('lease');
	if (context.role.hasPermission(RolePermission.CrossOrgWrite)) {
		if (leaseBalance.lease.isCollectionsActive) {
			context.stopChecks();
			return;
		}

		return 'support can only update balance if collections active';
	}
}
