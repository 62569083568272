























































import { Vue, Component, Prop } from '$/lib/vueExt';

@Component({ model : { prop : 'file' } })
export default class FileViewer extends Vue {

	@Prop()
	file: File;

	loading: boolean = true;
	rotation: number = 0;

	get isImage() {
		return this.file ? this.file.mimeType.match('image/*') : false;
	}

	get style() {
		return `transform : rotate(${this.rotation * 90}deg)`;
	}

	rotateClockwise() {
		this.rotation++;
	}

	rotateCounterClockwise() {
		this.rotation--;
	}

	onHidden() {
		this.rotation = 0;
		this.loading  = true;
		this.$emit('update:file', null);
		this.$emit('hidden');
	}

}

export interface File {
	name: string;
	mimeType: string;
	contentURL: string;
}
