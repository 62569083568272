import 'reflect-metadata';
import '$/lib/global';
import '$/lib/directives';
import '$/lib/widgets';
import '$/lib/widgets/icons';
import '@fontsource/roboto';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Axios           from 'axios';
import Moment          from 'moment';
import { RouteConfig } from 'vue-router';
import BootstrapVue, { BootstrapVueIcons } from 'bootstrap-vue';

import { hubspot }          from '$/lib/hubspot';
import tracker              from '$/lib/hubspot/tracker';
import { Vue }              from '$/lib/vueExt';
import initializers         from '$/lib/initializers';
import env, { Environment } from '$/lib/env';
import { loadPixel }        from '$/lib/MetaPixel';
import { loadUET }          from '$/lib/MicrosoftUET';
import { loadClarity }      from '$/lib/clarity';
import testsHelper          from '$/lib/testsHelper';
import log                  from '$/lib/logger';

import FrontLobbyPlugins from '$/plugins';
import GTag              from '$/plugins/vueGtag';

import '$/entities/index';	// make sure all entities are loaded for proper ORM de-serialization
import { loadEntityClasses } from '$/entities/BaseEntity';
import { BaseRole }          from '$/entities/roles/BaseRole';
import { Authentication }    from '$/entities/Authentication';
import { Applicant }         from '$/entities/roles/Applicant';
import { Renter }            from '$/entities/roles/Renter';

import Main   from '$/screens/Main.vue';
import router from '$/screens/router';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(FrontLobbyPlugins);

// mount the main view
(async function() {
	let role: BaseRole;
	const session  = await Authentication.getSession();
	let hasSession = !!session?.expiresOn;
	if (hasSession) {
		role = await BaseRole.loadCurrent({ redirectToLogin : false });
		if (!role) {
			hasSession = false;
		}
	}

	await setupTrackers(role, session);

	if (env.isEnvironment(Environment.DEV)) {
		void (await import('$/lib/browserSync')).default();
	}

	const rootRoutes: Dictionary<Module | string> = {};

	// don't combine the imports; this way webpack can tell that there's two dynamic dependencies
	// webpackChunkName is a magic webpack comment that names the output chunk
	if (env.isEnvironmentNot(Environment.PROD) && window.location.pathname.startsWith('/playground')) {
		rootRoutes['/playground'] = await import(/* webpackChunkName: "playground" */ './Playground/Playground.vue') as Module;
	}
	else if (!hasSession) {
		rootRoutes['/'] = await import(/* webpackChunkName: "login" */ './Login/Login.vue') as Module;
	}
	else if (env.app.isLCB) {
		rootRoutes['/'] = await import(/* webpackChunkName: "lcb" */ './LCB/LCB.vue') as Module;
	}
	else if (env.app.isFrontLobby) {
		if (role instanceof Applicant || role instanceof Renter) {
			rootRoutes['/applicant'] = await import(/* webpackChunkName: "applicant" */ './Applicant/Applicant.vue') as Module;
		}

		if (role instanceof Applicant) {
			rootRoutes['*'] = '/applicant';	  // default route into the applicant module
		}
		else {
			// SHOULDDO: split the App section up into Admin/Landlord/Renter
			rootRoutes['/'] = await import(/* webpackChunkName: "app" */ './App/App.vue') as Module;
		}
	}
	else {
		throw new Error(`unknown application: ${env.app.internalName}`);
	}

	registerRoutes(rootRoutes);

	// start up the Main component
	const main = new Main({ router });
	await initializers.waitForAll();
	router.onReady(() => {
		main.$mount('#root');
	});

	if (env.isEnvironmentNot(Environment.PROD)) {
		testsHelper.install(main as Vue);
	}

	// install CSP violation event handler and log any violations to the server
	if (window.hasOwnProperty('SecurityPolicyViolationEvent')) {
		document.addEventListener('securitypolicyviolation', function(event) {
			log.warn('CSP violation', _.pick(event, 'violatedDirective', 'blockedURI', 'effectiveDirective'));
		});
	}
})();

// loads all entity classes into the global namespace to facilitate manual entity calls from the console
(window as any).loadAllEntities = loadEntityClasses;
console.info('use `loadAllEntities()` to populate all entity classes into the global namespace');

function registerRoutes(rootRoutes: Dictionary<Module | string>) {
	_.forEach(rootRoutes, (moduleOrRedirect, path) => {
		if (typeof moduleOrRedirect === 'string') {
			router.addRoute({ path, redirect : moduleOrRedirect });
		}
		else {
			const component    = moduleOrRedirect.default;
			const moduleRoutes = moduleOrRedirect.routes as RouteConfig[];

			// include a default route to the first route (if a default route does not already exist)
			if (!moduleRoutes.find(route => route.path === '*')) {
				moduleRoutes.push({ path : '*', redirect : moduleRoutes[0].path });
			}

			router.addRoute({ path, component, children : moduleRoutes });
		}
	});

	// add main default route to point to the first route of the first module
	if (!router.getRoutes().find(route => route.path === '*')) {
		router.addRoute({ path : '*', redirect : (_.find(rootRoutes, module => typeof module !== 'string') as Module).routes[0].path });
	}
}

function setupSessionExpiry(expiresOn: Date) {
	// listen for "no session" errors coming back from API requests
	Axios.interceptors.response.use(null, error => error?.message === 'no session' ? signOut() : Promise.reject(error));

	// also try to pre-empty with a timer
	// note that this doesn't always fire on time due to tab inactivity
	setTimeout(signOut, Moment(expiresOn).subtract(1, 'minute').diff(Moment()));

	function signOut() {
		void Authentication.signOut({ routeTo : router.currentRoute.fullPath, message : 'Your session has expired' });
	}
}

async function setupTrackers(role: BaseRole, session: {expiresOn: Date}) {
	// Explicitly called before loading the tracker as HS Tracker needs this for SPAs to work
	tracker.recordRouteChange(window.location.pathname, window.location.search);

	void tracker.load();

	if (role) {
		hubspot.setContact(role.user.firstName, role.user.lastName, role.user.email, 'App');
		tracker.identify(role.user.email);
		GTag.identify(role.user);
		setupSessionExpiry(session.expiresOn);
	}

	tracker.captureUTMFieldsFromURL();
	void loadPixel();
	void loadUET();
	void loadClarity();
}

interface Module {
	routes: RouteConfig[];
	default: any;	// Vue component
}
