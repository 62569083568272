import { Platform } from '$/lib/env';
import { Mixin }    from '$/lib/utils';
import { TableInheritance, AfterUpdate, AfterInsert } from '$/lib/typeormExt';

import Permissions, { Context } from '$/entities/lib/Permissions';
import { Field, JSONable }      from '$/entities/lib/JSONable';
import {
	BaseEntity, CommonEntity, Entity, Column, ManyToOne, OneToOne, OneToMany,
	Alias, ChildEntity, JoinColumn, Validate
} from '$/entities/BaseEntity';

/**
 * This JSONable is used for automated tests.
 */
@Mixin(JSONable)
export class TestJSONable {

	@Field()
	numberField: number;

	@Field()
	stringField: string;

	@Field()
	dateField: Date;

	@Field()
	nestedJSONableField: TestJSONable;

	/**
	 * A class field not decorated with @Field and thus should not be included in the corresponding JSON.
	 * This type of field can be used for local/temporary storage
	 */
	nonField: string;

	constructor(initialValues?: Partial<TestJSONable>) {
		Object.assign(this, initialValues);
	}

}

/**
 * This entity and any subclasses are used for automated tests.
 */
@Entity('testEntity', { common : true })
@TableInheritance({ column : { name : '$class', type : 'varchar' } })
export class BaseTestEntity extends BaseEntity {
}

@ChildEntity() @CommonEntity()
export class TestEntity extends BaseTestEntity {

	@Column()
	stringField: string = '';

	@Column()
	numberField: number = 0;

	@Column('decimal', { precision : 10, scale : 2 })
	decimalField: number = 0;

	@ManyToOne('TestEntity', { onDelete : 'CASCADE' })
	parentTestEntity: TestEntity;

	@OneToMany('TestEntity', 'parentTestEntity', { persistence : false })
	children: TestEntity[];

	@OneToOne('TestEntity', { nullable : true })
	@JoinColumn()
	childTestEntity: TestEntity = undefined;

	@OneToOne('TestEntity', 'childTestEntity')
	inverseTestEntity: TestEntity = undefined;

	@Column({ type : 'json', nullable : true })
	jsonableField: TestJSONable;

	@ManyToOne('TestPermissions', { onDelete : 'CASCADE' })
	@Permissions({ read : Permissions.serverOnly })
	serverOnlyRelation: TestEntity;

	constructor(initialValues?: Partial<TestEntity>) {
		super();
		Object.assign(this, initialValues);
	}

}

@ChildEntity() @CommonEntity()
export class TestSubclass1 extends TestEntity {

	@Column()
	subclass1Field: number = 0;

}

@ChildEntity() @CommonEntity()
export class TestSubclass2 extends TestEntity {

	@Column()
	subclass2Field: number = 0;

}

@ChildEntity() @CommonEntity()
export class TestSubclass3 extends TestEntity {

	@Column() @Alias('aliasField')
	aliasedField: string = '';

	get aliasField() {
		return this.aliasedField;
	}
	set aliasField(newValue) {
		this.aliasedField = newValue;
	}

}

@ChildEntity() @CommonEntity()
export class TestSearching extends TestEntity {

	@Column()
	stringField2: string = '';

	@Column()
	stringField3: string = '';

	constructor(initialValues?: Partial<TestSearching>) {
		super();
		Object.assign(this, initialValues);
	}

}

@ChildEntity() @CommonEntity()
export class TestAfterEntity extends TestEntity {

	@AfterInsert()
	onAfterInsert() {
		this.numberField = this.isEdited('stringField') ? 1 : 0;
		this.stringField = 'afterInsert';
	}

	@AfterUpdate()
	onAfterUpdate() {
		this.numberField = this.isEdited('stringField') ? 3 : 2;
		this.stringField = 'afterUpdate';
	}

}

@ChildEntity() @CommonEntity()
@Permissions({
	create : (context: Context, permTest: TestPermissions) => permTest.createError,
	read   : (context: Context, permTest: TestPermissions) => permTest.readError,
})
export class TestPermissions extends BaseTestEntity {

	createError: string;
	readError: string;

	@Column()
	@Permissions({
		write : function(context: Context) {
			return context.platform ===  Platform.Server ? '' : 'client not allowed';
		},
	})
	serverWriteOnly: string;

	@Column()
	@Permissions({
		write : function(context: Context, permTest: TestPermissions) {
			if (permTest.column1 === 42) {
				context.stopChecks();	// overrides class errors
			}
			return '';
		},
	})
	column1: number;

}

@ChildEntity() @CommonEntity()
export class TestExtraValidation extends TestEntity {

	@ManyToOne('TestEntity', { onDelete : 'CASCADE' })
	@Validate({ requiredRelation : true })
	parentTestEntity: TestEntity;		// redefine the field and make it required in this class

}
