import { Field, JSONable } from '$/entities/lib/JSONable';

export enum VerificationType {
	Kount  = 'kount',
	Upload = 'Upload',
}

export enum VerificationStatus {
	Approved  = 'approved',
	Rejected  = 'rejected',
	Submitted = 'submitted'
}

export class VerificationResult extends JSONable {

	constructor(type: VerificationType, status: VerificationStatus, score?: number, reasons?: string[], additionalData?: any) {
		super();
		this.date           = new Date();
		this.type           = type;
		this.status         = status;
		this.score          = score;
		this.reasons        = reasons;
		this.additionalData = additionalData;
	}

	@Field()
	type: VerificationType;

	@Field()
	date: Date;

	// @deprecated
	@Field()
	passed: boolean;

	@Field()
	status: VerificationStatus;

	@Field()
	score?: number;

	@Field()
	reasons?: string[];

	@Field()
	invalid?: boolean;

	@Field()
	additionalData?: any;

}
