






import { Vue, Component, Prop, Watch, Provide, ProvideReactive } from '$/lib/vueExt';

import type Step from './Step.vue';

@Component
export default class Steps extends Vue {

	// #region currentStep
	@Prop(Number)
	readonly value: number;

	@ProvideReactive()
	currentStep: number = -1;

	@Watch('value')
	onValueChange(newValue, oldValue) {
		newValue = toInt(newValue, -1);
		oldValue = toInt(oldValue, 0);

		const step = this.steps[newValue];
		if (step && !step.disabled) {
			this.activateStep(step);
		}
		else if (newValue < oldValue) {
			void this.previousStep();
		}
		else {
			void this.nextStep();
		}
	}

	@Watch('currentStep')
	onCurrentStepChange(newValue) {
		let index = -1;

		this.steps.forEach((step, i) => {
			if (i === newValue && !step.disabled) {
				step.localActive = true;
				step.$emit('activate');
				index = i;
			}
			else {
				step.localActive = false;
				step.$emit('deactivate');
			}
		});

		this.$emit('change', index);
	}
	// #endregion

	@ProvideReactive()
	steps: Step[] = [];
	registeredSteps: Step[] = [];

	@Provide()
	registerStep(step: Step) {
		if (!this.registeredSteps.includes(step)) {
			this.registeredSteps.push(step);
		}
	}

	@Provide()
	unregisterStep(step: Step) {
		this.registeredSteps = this.registeredSteps.filter(s => s !== step);
	}

	@Watch('registeredSteps')
	updateSteps() {
		const steps = this.getSteps();

		let stepIndex = _.findLastIndex(steps, step => step.localActive && !step.disabled);

		if (stepIndex < 0) {
			const currentStep = this.currentStep;
			if (currentStep >= steps.length) {
				stepIndex = _.findLastIndex(steps, notDisabled);
			}
			else if (steps[currentStep] && !steps[currentStep].disabled) {
				stepIndex = currentStep;
			}
		}

		if (stepIndex < 0) {
			stepIndex = _.findIndex(steps, notDisabled);
		}

		steps.forEach((step, index) => {
			step.localActive = index === stepIndex;
		});

		this.steps       = steps;
		this.currentStep = stepIndex;
	}

	getSteps() {
		const steps = this.registeredSteps.filter(step => step._isStep);

		let order      = [];
		const selector = steps.map(step => `#${step.localId}`).join(', ');
		if (selector) {
			order = Array.from(this.$el.querySelectorAll(selector))
				.map(el => el.id)
				.filter(x => x);
		}

		return steps.sort((a, b) => order.indexOf(a.localId) - order.indexOf(b.localId));
	}

	@Provide()
	activateStep(step: Step) {
		let result = false;

		if (step) {
			const index = this.steps.indexOf(step);
			if (index !== this.currentStep && index > -1 && !step.disabled) {
				const stepEvent =  new Event('activate-step', {
					cancelable : true,
				});

				this.$emit(stepEvent.type, index, this.currentStep, stepEvent);

				if (!stepEvent.defaultPrevented) {
					this.currentStep = index;
					result           = true;
				}
			}
		}

		if (!result && this.value !== this.currentStep) {
			this.$emit('change', this.currentStep);
		}

		return result;
	}

	@Provide()
	deactivateStep(step: Step) {
		if (step) {
			return this.activateStep(this.steps.filter(s => s !== step).find(notDisabled));
		}
		return false;
	}

	@Provide()
	async nextStep() {
		const currentIndex = Math.max(this.currentStep, -1);
		const currentStep  = this.steps[currentIndex];
		let nextIndex      = currentIndex;
		let nextStep;

		// Sometimes the next step can get disabled during the onNext
		// If that happens, keep trying to move to the next step until we find one that isn't disabled or we run out of steps
		while (!nextStep || nextStep.disabled) {
			nextStep = this.steps.slice(++nextIndex).find(notDisabled);

			if (!nextStep) {
				await this.done();
				return;
			}

			if (!(await currentStep.onNext(nextStep, currentStep))) {
				return; // SHOULDDO: Notify user of failure
			}
		}

		await nextStep.beforeActivated();
		this.activateStep(nextStep);
	}

	@Provide()
	async previousStep() {
		const currentIndex = Math.max(this.currentStep, 0);
		const currentStep  = this.steps[currentIndex];
		const prevStep     = _.findLast(this.steps.slice(0, currentIndex), notDisabled);

		if (!(await currentStep.onPrevious(prevStep, currentStep))) {
			return; // SHOULDDO: Notify user of failure
		}

		this.activateStep(prevStep);
	}

	@Provide()
	async done() {
		const step = this.steps[this.currentStep];
		if (await step?.onNext(null, step)) {
			this.$emit('finish');
		}
	}

	mounted() {
		this.currentStep = toInt(this.value, -1);
	}

}

function toInt(value, defaultValue = NaN) {
	const integer = parseInt(value, 10);
	return isNaN(integer) ? defaultValue : integer;
}

function notDisabled(step: Step) {
	return !step.disabled;
}
