



















































import { Vue, Component, Prop } from '$/lib/vueExt';
import env                      from '$/lib/env';
import PreserveState            from '$/lib/PreserveState';

import { Support } from '$/entities/roles/Support';
import Tool        from './Tool.vue';

/**
 * A Tools component allows for non-production tools to be displayed and accessible to the user for the purposes of testing and development.
 * Tools components do not appear in production environments.
 */
@Component({
	components : { Tool },
})
export default class Tools extends Vue {

	// SHOULD DO: add support for more than 1 level of grouping
	@Prop({ default : () => ({}) })
	readonly tools: Dictionary<Function | Dictionary<Function>>;

	/**
	 * Whether or not the purple gears are hidden (useful for demo purposes)
	 */
	@PreserveState.LocalStorage('preferences')
	videoModeEnabled = false;

	uniqueID = `id_${Math.random()}`;

	get isVisible() {
		return !env.isProd && (!this.videoModeEnabled || this.$role instanceof Support);
	}

	get body() {
		return document.body;
	}

	getFormattedTitle(originalTitle: string) {
		// if title includes any of these characters, it's probably already formatted so don't mess with it
		return /[ .:'"()-]/.test(originalTitle) ? originalTitle : _.startCase(originalTitle);
	}

}

