import Axios                              from 'axios';
import { Answers, Questionnaire, Result } from '$/lib/equifax/KountClient';

import { KountVerifier as KountVerifierCommon } from '$/common/lib/identityVerification/kountVerifier';

export class KountVerifier extends KountVerifierCommon {

	async getQuestions(): Promise<Questionnaire | Result> {
		return (await Axios.get(KountVerifier.getURL())).data;
	}

	async submitAnswers(answers: Answers) {
		return (await Axios.post(KountVerifier.getURL(), answers)).data;
	}

	static getURL(suffix = ''): string {
		return `/api/identity-verification/kount${_.ensureStartsWith(suffix, '/')}`;
	}

}

export default new KountVerifier();
