





















import { Vue, Component, Prop } from '$/lib/vueExt';

/**
 * An InfoTip displays an icon which can be hovered upon to bring up a popover with more information.
 */
@Component({ inheritAttrs : false })
export default class InfoTip extends Vue {

	@Prop({ default : 'auto' })
	readonly placement: string;

	@Prop({ default : 'question-circle' })
	readonly icon: string;

	@Prop({ default : '' })
	readonly iconVariant: string;

	@Prop({ default : '' })
	readonly text: string;

	uniqueID = `id_${Math.random()}`;

	get body() {
		return document.body;
	}

	hide() {
		(this.$refs.popover as any)?.$emit('close');
	}

	showTooltip() {
		if (this.$breakpoint.onMobile) {
			void this.$bvModal.msgBoxOk(this.$slots.default, { centered : true, title : this.$attrs.title });
		}
	}

}
