import Moment           from 'moment';
import parsePhoneNumber from 'libphonenumber-js';

import random                              from '$/lib/Random';
import { Index, SelectQueryBuilder }       from '$/lib/typeormExt';
import Validate                            from '$/lib/Validate';
import { RenterProxies }                   from '$/lib/RenterProxies';
import { Country, CountryRegions }         from '$/lib/Address';
import { DayTransformer, JSONTransformer } from '$/lib/columnTransformers';

import { Organization }       from '$/entities/Organization';
import { Lease, LeaseStatus } from '$/entities/Lease';
import { NameSuffixOptions }  from '$/entities/User';
import Permissions            from '$/entities/lib/Permissions';
import { Renter }             from '$/entities/roles/Renter';
import { Tenant }             from '$/entities/Tenant';
import { Landlord }           from '$/entities/roles/Landlord';
import { Address }            from '$/entities/Address';
import type { Context }       from '$/entities/lib/Permissions';
import { Entity, Column, ManyToOne, BaseEntity }    from '$/entities/BaseEntity';
import { Building, BuildingUnitType, BuildingUnit } from '$/entities/Building';

export enum LeaseDraftStatus {
	Pending  = 'pending',
	Rejected = 'rejected',
	Accepted = 'accepted',
}

export interface RenterInfo {
	firstName: string;
	middleName: string;
	lastName: string;
	suffix: NameSuffixOptions;
	dateOfBirth: Date;
	email: string;
	phoneNumber?: string;
	proxy?: RenterProxies;

	/**
	 * if false, do not send accept and reject draft lease emails
	 */
	sendDraftNotificationEmails?: boolean;
	acceptedTOC?: boolean;
}

@Entity('leaseDraft', { common : true })
@Permissions({
	create : renterWrite,
	read   : renterOrLandlordRead,
	update : renterWrite,
	delete : renterWrite,
})
export class LeaseDraft extends BaseEntity {

	@Column({ type : 'enum', enum : Object.values(LeaseDraftStatus), default : LeaseDraftStatus.Pending })
	@Permissions({ write : canLandlordWrite })
	status: LeaseDraftStatus = LeaseDraftStatus.Pending;

	@Column({ type : 'varchar', length : 1000 })
	@Permissions({ write : canLandlordWrite })
	rejectionReason: string = '';

	@Index()
	@Column({ type : 'varchar' })
	@Validate({ required : true, email : true, custom : notEqualToSelfValidator })
	email: EmailAddress = '';

	@Column()
	@Validate({ required : true, trimmed : true })
	name: string = '';

	@Column()
	@Validate({ phoneNumber : { strict : true } })
	phoneNumber: string = '';

	@Column({ type : 'enum', enum : Object.values(LeaseStatus), default : LeaseStatus.Current })
	@Validate({ required : true, enum : LeaseStatus })
	leaseStatus: LeaseStatus = LeaseStatus.Current;

	@Column('decimal', { precision : 10, scale : 2 })
	@Validate({ required : true, min : 1, max : 25000 })
	monthlyAmount: number = 0;

	@Column({ type : 'date', transformer : DayTransformer })
	@Validate({ required : true, custom : checkMoveIn })
	moveIn: Date;

	@Column({ type : 'date', nullable : true, transformer : DayTransformer })
	@Validate({
		date     : true,
		required : function() {
			return this.isFormer && !this.endDate;
		},
	})
	endDate: Date;

	@Column()
	@Validate({ required : true, enum : BuildingUnitType })
	unitType: BuildingUnitType = '' as BuildingUnitType;

	@Column()
	@Validate({ required : true })
	unitBedrooms: number;

	@Column({ type : 'json', default : () => "('{}')" })
	@Validate({
		required  : true,
		recursive : {
			street     : { required : true },
			city       : { required : true },
			province   : { required : true },
			country    : { required : true },
			postalCode : { required : true },
		},
	})
	address: Address = new Address();

	@Column({ asExpression : "address->>'$.street'", nullable : true })
	get street() {
		return this.address.street;
	}
	set street(value: string) {
		this.address.street = value;
	}

	@Column({ asExpression : "address->>'$.city'", nullable : true })
	get city() {
		return this.address.city;
	}
	set city(value: string) {
		this.address.city = value;
	}

	@Column({ asExpression : "address->>'$.province'", nullable : true })
	get province() {
		return this.address.province;
	}
	set province(value: string) {
		this.address.province = value;
	}

	@Column({ length : '2', asExpression : "address->>'$.country'", nullable : true })
	get country() {
		return this.address.country;
	}
	set country(value: Country) {
		this.address.country = value;
	}

	@Column({ asExpression : "address->>'$.postalCode'", nullable : true })
	get postalCode() {
		return this.address.postalCode;
	}
	set postalCode(value: string) {
		this.address.postalCode = value;
	}

	@Column({ asExpression : "address->>'$.unitNumber'", nullable : true })
	get unitNumber() {
		return this.address.unitNumber;
	}
	set unitNumber(value: string) {
		this.address.unitNumber = value;
	}

	@ManyToOne('Organization', { onDelete : 'SET NULL' })
	@Permissions({ write : Permissions.serverOnly })
	landlordOrg: Organization = undefined;

	@ManyToOne('Renter', { onDelete : 'CASCADE' })
	@Permissions({ write : Permissions.serverOnly })
	renter: Renter = undefined;

	@Column({ type : 'json', transformer : JSONTransformer({ strict : false  }), nullable : true })
	@Permissions({ write : Permissions.serverOnly })
	renterInformation: RenterInfo;

	/**
	 * Used to optionally link lease draft to records in external systems
	 * @member {string} externalId
	 * **/
	@Column({ type : 'varchar', length : 50, default : '' })
	externalId: string = '';

	get isCurrent() {
		return this.leaseStatus === LeaseStatus.Current;
	}

	get isFormer() {
		return this.leaseStatus === LeaseStatus.Former;
	}

	get fullAddress() {
		return this.address.format();
	}

	async toLease() {
		const lease         = new (Lease as unknown as Class)() as Lease;
		const tenant        = this.getTenant();
		const building      = await this.getBuilding();
		tenant.lease        = lease;
		lease.tenants       = [ tenant ];
		lease.status        = this.leaseStatus;
		lease.endDate       = this.endDate;
		lease.monthlyAmount = this.monthlyAmount;
		lease.draft         = this as any;

		if (building) {
			lease.building = building;
			lease.unit     = this.unitNumber;
		}

		return lease;
	}

	private getTenant() {
		const tenant  = new Tenant();
		tenant.moveIn = this.moveIn;
		tenant.org    = (this as any).landlordOrg;
		Object.assign(tenant, this.renterInformation);
		return tenant;
	}

	private async getBuilding() {
		await this.loadRelation('landlordOrg');
		if (!this.landlordOrg) {
			return;
		}

		// SHOULD DO: Have a better way to match existing properties
		const where = { org : this.landlordOrg, ..._.pick(this, 'street', 'city', 'province', 'country') };

		const building = await Building.findOne({ where }) ?? Object.assign(
			new Building(),
			{ org : this.landlordOrg },
			_.pick(this, 'street', 'city', 'province', 'country', 'postalCode')
		);

		if (!building.units.some(unit => unit.number === this.unitNumber)) {
			building.makeEditable({ force : true });
			building.units.push(new BuildingUnit({
				number   : this.unitNumber,
				type     : this.unitType,
				bedrooms : this.unitBedrooms,
			}));
		}

		return building;
	}

	static generateAtRandom(draft = new this()) {
		draft.name          = random.fullName();
		draft.email         = random.email();
		draft.phoneNumber   = parsePhoneNumber(random.phoneNumber(), Country.CA).number;
		draft.leaseStatus   = random.pickOne(Object.values(LeaseStatus)) as LeaseStatus;
		draft.moveIn        = random.date({ max : Moment().subtract(1, 'year').toDate() });
		draft.monthlyAmount = random.integer(1000, 4000);

		if (draft.isFormer) {
			draft.endDate = random.date({ min : Moment.max(Moment(draft.moveIn), Moment().subtract(6, 'years')).toDate(), max : new Date() });
		}

		draft.unitNumber   = random.integer(100, 999).toString();
		draft.unitType     = random.pickOne(Object.values(BuildingUnitType)) as BuildingUnitType;
		draft.unitBedrooms = random.integer(-1, 9);
		draft.street       = random.street();
		draft.city         = random.city();
		draft.country      = Country.CA;
		draft.province     = random.pickOne(Object.keys(CountryRegions[draft.country]));
		draft.postalCode   = random.postalCode();

		draft.renterInformation = {
			email       : random.email(),
			dateOfBirth : random.birthday(),
			firstName   : random.firstName(),
			middleName  : '',
			lastName    : random.lastName(),
			suffix      : '' as NameSuffixOptions,
		};

		return draft;
	}

}

function renterWrite(context: Context, entity: LeaseDraft) {
	if (!(context.role instanceof Renter)) {
		return 'you must be a renter to access the requested resource';
	}

	if (!context.role.isVerified) {
		return 'you must be verified to access the record builder';
	}

	if (!(entity.renter || (entity as any).renterId) || (entity as any).renterId !== context.role.id) {
		if (!entity.isNew) {
			return 'renter does not match your role';
		}
	}

	if (entity.isNew) {
		entity.renter = context.role;
	}
}

function canLandlordWrite(context: Context, entity: LeaseDraft) {
	if (context.role instanceof Landlord) {
		if (context.org.id !== (entity as any).landlordOrgId) {
			return 'you do not have access to edit this lease request.';
		}
		context.stopChecks();
	}
}

function renterOrLandlordRead(context: Context, entity: LeaseDraft, query: SelectQueryBuilder<LeaseDraft>) {
	if (query) {
		const alias       = query.expressionMap.mainAlias.name;
		let queryString   = `${alias}.renterId = :renterId`;
		const params: any = {
			renterId : context.role.id,
		};

		if (context.role instanceof Landlord) {
			queryString  += ` OR ${alias}.landlordOrgId = :orgId`;
			params.orgId  = context.org.id;
		}

		query.andWhere(`(${queryString})`, params);
	}

	else {
		if (context.role instanceof Landlord && context.org.id === (entity as any).landlordOrgId) {
			return;
		}

		if (context.role.id === (entity as any).renterId) {
			return;
		}

		return 'insufficient permission';
	}
}

async function checkMoveIn(value) {
	if (!value) {
		return '';
	}

	if (Moment(value).isAfter(Moment(), 'day')) {
		return `Date must be equal or earlier than: ${Moment().format('ll')}`;
	}

	if (this.endDate && Moment(value).isAfter(this.endDate, 'month')) {
		return `Cannot be after lease end date: ${Moment(this.endDate).format('ll')}`;
	}

	return '';
}

async function notEqualToSelfValidator(value: string): Promise<string> {
	await this.loadRelation('renter');
	return value !== this.renter?.user.email && (!this.renterInformation || value !== this.renterInformation?.email) ? '' : 'You cannot invite yourself';
}
