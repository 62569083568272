/**
 * Base class for all identity verification implementations.
 */
export abstract class BaseVerifier {

	static getVerifierName(): string {
		return _.camelCase(this.name);
	}

	static getURL(suffix = ''): string {
		return `/api/identity-verification/${this.getVerifierName()}${_.ensureStartsWith(suffix, '/')}`;
	}

}
