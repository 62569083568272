










import { Vue, Component, Prop, InjectReactive } from '$/lib/vueExt';

import type Step from './Step.vue';

@Component
export default class StepTitle extends Vue {

	@Prop({ default : '' })
	readonly title;

	@InjectReactive()
	readonly steps: Step[];

	@InjectReactive()
	readonly currentStep: number;

	get computedTitle() {
		const title = this.steps?.[this.currentStep]?.title || this.title || '';
		return title.replaceAll('{#}', this.stepNumber + 1);
	}

	get stepNumber() {
		return this.steps ? this.steps.filter(step => !step.disabled).indexOf(this.steps[this.currentStep]) : 0;
	}

	get stepCount() {
		return this.steps.filter(step => !step.disabled).length;
	}

}
