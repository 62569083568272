import Axios       from 'axios';
import { Country } from '$/lib/Address';

import { Entity }   from '$/entities/BaseEntity';
import { BaseRole } from '$/entities/roles/BaseRole';
import { Address }  from '$/entities/Address';

import { Building as BuildingCommon } from '$/common/entities/Building';
export *                              from '$/common/entities/Building';

@Entity()
export class Building extends BuildingCommon {

	static getSample() {
		const sample    = super.getSample();
		sample.country  = BaseRole.current.org.country || sample.country;
		sample.province = BaseRole.current.org.province || sample.province;
		sample.city     = 'Sample City';
		return sample;
	}

	static async findCountyName(address: Address): Promise<string[]> {
		if (address.country !== Country.US) {
			return [];
		}
		const params = _.compactObject({
			street     : address.street,
			city       : address.city,
			province   : address.province,
			country    : address.country,
			postalCode : address.postalCode,
		});

		return (await Axios.get(Building.collectionUrl('/county'), { params })).data ?? [];
	}

}
