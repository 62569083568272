















/**
 * This component displays a popup with a prompt to Verify Identity
 */
import { Vue, Component, Prop } from '$/lib/vueExt';

@Component
export default class IdentityVerificationPrompt extends Vue {

	/**
	 * Show redirect to upgrade button only on table view
	 */
	@Prop({ type : Boolean, default : true })
	showButton: boolean;

}
