






import { Vue, Component, Prop, Watch, Inject, COMPONENT_UID_KEY } from '$/lib/vueExt';

@Component
export default class Step extends Vue {

	@Prop({ default : false })
	readonly disabled: boolean;

	@Prop({ default : false })
	readonly active: boolean;

	@Prop({ default : '' })
	readonly title: string;

	@Prop({ default : '' })
	readonly nextLabel: string;

	@Prop({ default : '' })
	readonly prevLabel: string;

	@Prop({ default : '' })
	readonly finishLabel: string;

	/**
	 * If true, this step should not show the back button to go to the previous step.
	 */
	@Prop({ default : false })
	readonly hideBack: boolean;

	@Prop({ default : false })
	readonly hideNext: boolean; // Don't allow forwards navigation from this step (e.g. final step with custom "finish" button in the step itself)

	/**
	 * If true, exclude this step from appearing in the progress dots in the navigation, and don't show the progress when on it.
	 */
	@Prop({ default : false })
	readonly noProgress: boolean;

	@Prop({ default : () => true })
	readonly onNext: (step: Step, oldStep: Step) => boolean | Promise<boolean>;

	@Prop({ default : () => true })
	readonly onPrevious: (step: Step, oldStep: Step) => boolean | Promise<boolean>;

	@Prop({ default : () => true })
	readonly beforeActivated: () => void | Promise<void>;

	localId: string = '';

	localActive: boolean = false;

	get _isStep() {
		return true;
	}

	@Inject()
	readonly registerStep: (step: Step) => void;

	@Inject()
	readonly unregisterStep: (step: Step) => void;

	@Inject()
	readonly activateStep: (step: Step) => boolean;

	@Inject()
	readonly deactivateStep: (step: Step) => boolean;

	@Watch('active')
	onActiveChange(newValue, oldValue) {
		if (newValue !== oldValue) {
			if (newValue) {
				this.activate();
			}
			else if (!this.deactivate()) {
				this.$emit('update:active', this.localActive);
			}
		}
	}

	mounted() {
		this.localId = `_step_${this[COMPONENT_UID_KEY]}`;
		this.registerStep?.(this);
	}

	beforeDestroy() {
		this.unregisterStep?.(this);
	}

	activate() {
		this.$emit('activate');
		return this.activateStep && !this.disabled ? this.activateStep(this) : false;
	}

	deactivate() {
		this.$emit('deactivate');
		return this.deactivateStep && this.localActive ? this.deactivateStep(this) : false;
	}

}
