import { BaseEntity, Column, Entity, ManyToOne, Validate } from '$/entities/BaseEntity';
import Permissions        from '$/entities/lib/Permissions';
import { RolePermission } from '$/entities/roles/RolePermission';
import { Coupon }         from '$/entities/billing/Coupon';

const affiliateCodeChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';

@Entity({ common : true })
@Permissions({
	create : Permissions.roleHasPermission(RolePermission.AffiliateWrite),
	update : Permissions.roleHasPermission(RolePermission.AffiliateWrite),
	delete : Permissions.roleHasPermission(RolePermission.AffiliateWrite),
})
export class Affiliate extends BaseEntity {

	constructor(data?: Partial<Affiliate>) {
		super();
		this.mergeColumns(data);
	}

	/**
	 * The name of the Affiliate, use for internal purposes.
	 */
	@Column()
	@Validate({ required : true, trimmed : true })
	@Permissions({ read : Permissions.roleHasPermission(RolePermission.AffiliateInternalRead) })
	name: string = '';

	/**
	 * The url of the affiliate's landing page, which can be used to check if a user found us through that affiliate by checking HubSpot activity
	 */
	@Column({ unique : true })
	@Validate({ required : true, url : true })
	@Permissions({ read : Permissions.roleHasPermission(RolePermission.AffiliateInternalRead) })
	landingPageUrl: string = '';

	@Column({ length : 25, unique : true })
	@Validate({ required : true, trimmed : true, minLength : 6, maxLength : 25, allowChars : affiliateCodeChars })
	@Permissions({ read : Permissions.roleHasPermission(RolePermission.AffiliateInternalRead) })
	affiliateCode: string = '';

	@ManyToOne('Coupon', { onDelete : 'SET NULL' })
	@Validate({ required : true })
	@Permissions({ read : Permissions.roleHasPermission(RolePermission.AffiliateInternalRead) })
	coupon: Coupon;

	/**
	 * The end-user description that describes the reward when the user is associated with the affiliate.
	 */
	@Column()
	@Validate({ required : true })
	rewardDescription: string = '';

}
