














































import { Vue, Component, Prop, Inject, InjectReactive } from '$/lib/vueExt';
import Step from './Step.vue';

@Component
export default class StepNavigation extends Vue {

	@Prop({ default : false })
	readonly hideProgress: boolean;

	@Prop({ default : 'Next' })
	readonly nextLabel: string;

	@Prop({ default : 'Back' })
	readonly prevLabel: string;

	@Prop({ default : 'Done' })
	readonly finishLabel: string;

	/**
	 * If true, shows the Prev button even on the first step.
	 * This allows for going "back" past the first step, intended to allow for cancelling the steps flow.
	 */
	@Prop({ default : false })
	readonly allowPrevOnFirstStep: boolean;

	@Prop({ default : '' })
	readonly prevLabelFirstStep: string;

	@Prop({ default : '' })
	readonly buttonDivClass: string;

	@Prop({ default : false })
	readonly block: boolean;

	@InjectReactive()
	readonly steps: Step[];

	@InjectReactive()
	readonly currentStep: number;

	@Inject()
	readonly nextStep: () => Promise<void>;

	@Inject()
	readonly previousStep: () => void;

	@Inject()
	readonly done: () => Promise<void>;

	private get currStep() {
		return this.steps[this.currentStep];
	}

	get canPrev() {
		return this.currentStep === 0 && this.allowPrevOnFirstStep
			|| (!!this.steps.slice(0, this.currentStep).filter(step => !step.disabled).length && !this.currStep?.hideBack);
	}

	get canNext() {
		return !!this.steps.slice(this.currentStep + 1).filter(step => !step.disabled).length;
	}

	get isNextVisible() {
		return !this.currStep?.hideNext;
	}

	get isProgressVisible() {
		return !this.hideProgress && !this.currStep?.noProgress;
	}

	get computedNextLabel() {
		return this.currStep?.nextLabel || this.nextLabel;
	}

	get computedPrevLabel() {
		return this.currStep?.prevLabel || this.prevLabel;
	}

	get computedFinishLabel() {
		return this.currStep?.finishLabel || this.finishLabel;
	}

	get localButtonDivClass() {
		return this.buttonDivClass || (this.canPrev ? 'justify-content-between' : 'justify-content-end');
	}

	onPrev() {
		if (!this.currentStep) {
			this.$emit('cancel');
		}
		this.previousStep?.();
	}

	async onNext() {
		await this.nextStep?.();
	}

	// Looks weird, but have to do this or Vue yells about mutating the injected function (I think due to lcb-busy modifying it)
	async onFinish() {
		await this.done();
	}

}
