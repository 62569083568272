import Axios      from 'axios';
import { Entity } from '$/entities/BaseEntity';

import { File as FileCommon } from '$/common/entities/File';
export                      * from '$/common/entities/File';

@Entity()
export class File extends FileCommon {

	nativeFile = null; // native File object

	async getContents(): Promise<Buffer> {
		if (this.nativeFile) {
			return this.nativeFile.arrayBuffer();
		}

		return (await Axios.get(File.collectionUrl(`${this.id}/content`))).data;
	}

}
