import Axios              from 'axios';
import Moment             from 'moment';
import { Vue }            from '$/lib/vueExt';
import { In }             from '$/lib/typeormExt';
import log, { LogEvents } from '$/lib/logger';

import { Email }         from '$/entities/emails/Email';
import { Entity }        from '$/entities/BaseEntity';
import { LeaseBalance }  from '$/entities/LeaseBalance';
import { NationalID }    from '$/entities/NationalID';
import type { Tenant }   from '$/entities/Tenant';
import type { File }     from '$/entities/File';
import type { BaseRole } from '$/entities/roles/BaseRole';

import { Lease as LeaseCommon, SampleEmails } from '$/common/entities/Lease';
export *                                      from '$/common/entities/Lease';

@Entity()
export class Lease extends LeaseCommon {

	setRentReporting(value: boolean, role: BaseRole) {
		super.setRentReporting(value, role);
		log.user(LogEvents.RentReporting, { value : `${!!value}`, item : this.id });
	}

	async uploadCollectionDocs(docs: File[], progressUpdate?: (uploadProgressPercentage: number) => void) {
		if (!docs.length) {
			return;
		}

		const formData = new FormData();
		docs.filter(doc => doc.nativeFile).forEach(file => {
			formData.append(`${file.documentType}-${file.reference}`, file.nativeFile, file.nativeFile.name);
		});

		await Axios.post(Lease.collectionUrl(`${this.id}/collectionDocs`), formData, { onUploadProgress : progressEvent => {
			progressUpdate?.call(this, progressEvent.loaded / progressEvent.total);
		} });
	}

	async disableReporting(vueContext: Vue, draftOnly = false) {
		if (!this.collections && !this.debtReporting  && !this.pendingDebtReporting && !this.isCollectionsDraft) {
			return true; // Reporting isn't enabled, so we don't need to disabled it, but returning false would cancel saving
		}

		await this.loadRelation('latestLeaseBalance');

		// SHOULDDO MAINTENANCE: switch this to a fl-confirmation-modal
		const confirmationNodes = [];
		let title               = '';
		if (!draftOnly && this.debtReporting) {
			title = 'Disable Debt Reporting';
			confirmationNodes.push(
				vueContext.$createElement('p', [ 'This will stop FrontLobby from assisting you with collecting the debt from this tenant.' ]),
				vueContext.$createElement('p', [ 'Warning: If you disable Debt Reporting, the only way to turn it back on is by paying again.' ])
			);
		}
		else if (this.isCollectionsDraft) {
			title = 'Discard Draft';
			confirmationNodes.push(
				vueContext.$createElement('p', [ 'Are you sure you want to discard the collections draft?' ])
			);
		}
		else if (this.collections) {
			title = 'Disable Collections';
		}
		else if (this.pendingDebtReporting) {
			title = 'Disable Pending Debt Reporting';
			confirmationNodes.push(
				vueContext.$createElement('p', [ 'Debt Reporting was automatically enabled for this lease because the amount owed exceeds your debt reporting threshold.' ]),
				vueContext.$createElement('p', [ 'You have not yet been charged for Debt Reporting on this lease. If you disable it now, you will not be charged.' ]),
				vueContext.$createElement('p', [ 'Are you sure you want to disable Debt Reporting?' ])
			);
		}

		let recovered = this.latestLeaseBalance?.amount ?? 0;
		if ((this.debtReporting || this.collections) && !(draftOnly && this.isCollectionsDraft)) {
			confirmationNodes.push(vueContext.$createElement('b-form-group', {
				attrs : {
					label : 'Please enter the amount you recovered.',
				},
			}, [
				vueContext.$createElement('fl-form-currency', {
					props : {
						value : recovered,
					},
					on : {
						input : (value: number) => {
							recovered = value;
						},
					},
				}),
			]));
		}

		const confirmed = await vueContext.showConfirm(confirmationNodes, { centered : false, title, okTitle : title });
		if (!confirmed) {
			return confirmed;
		}

		if (!draftOnly && this.debtReporting) {
			const balance  = await LeaseBalance.getFor(this, Moment().startOf('month').toDate(), { create : true });
			balance.amount = Math.max(0, this.latestLeaseBalance.amount - recovered);

			// The lease balance already belongs to the lease, but won't show up from the lease side of the relation until it's saved
			// Add it manually so it appears in the payment records section of the lease modal
			if (balance.isNew) {
				this.leaseBalances = this.leaseBalances || [];
				this.leaseBalances.push(balance);
			}

			this.makeEditable();
			this.setDebtReporting(false, vueContext.$role);
			this.reportingStatus.recovered = recovered;
		}
		else if (this.pendingDebtReporting) {
			this.setDebtReporting(false, vueContext.$role);
		}
		else if (!this.debtReporting) {
			await this.setCollectReporting(false, vueContext.$role);
		}

		return confirmed;
	}

	async getSampleEmail(type: SampleEmails, tenant: Tenant): Promise<Email> {
		const getConfig = {
			params : {
				leaseID   : this.isNew ? '' : this.id,
				firstName : tenant.firstName,
				lastName  : tenant.lastName,
			},
		};

		const data     = (await Axios.get(Lease.collectionUrl(`/sampleEmails/${type}`), getConfig)).data;
		const email    = new Email();
		email.to       = data.to;
		email.subject  = data.subject;
		email.htmlBody = data.htmlBody;
		return email;
	}

	/**
	 * Used for the "missing info" dashboard actions to filter for leases where at least one tenant is missing an SXN
	 * Can't be done in the query filters on those actions because they can't handle relations more than one step away
	 * Also there isn't a way to test if a relation doesn't exist via the where
	 * @returns True if there is at least one tenant on this lease missing a national ID, false otherwise
	 */
	async hasTenantsMissingNationalID() {
		await this.loadRelation('tenants');
		return await NationalID.count({ where : { tenantId : In(this.tenants.map(t => t.id)) } }) < this.tenants.length;
	}

}
