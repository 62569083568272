import Bytes, { Unit, BytesTransformer } from '$/lib/Bytes';
import { Index, SelectQueryBuilder }     from '$/lib/typeormExt';
import Errors                            from '$/lib/Errors';
import Validate                          from '$/lib/Validate';

import { DocumentType }                     from '$/entities/FileExt';
import Permissions, { Context }             from '$/entities/lib/Permissions';
import { BaseOrgEntity, isMyOrganization }  from '$/entities/Organization';
import { RolePermission }                   from '$/entities/roles/RolePermission';
import { Lease }                            from '$/entities/Lease';
import { CollectionsStatus, ReportingType } from '$/entities/ReportingStatus';
import { BaseEntity, Entity, Column, getEntityClass, Alias } from '$/entities/BaseEntity';

const ImageExtensions       = [ 'png', 'jpg', 'jpeg', 'gif' ];
const ImageMimeTypes        = [ 'image/png', 'image/jpeg', 'image/pjpeg', 'image/gif' ];
const originalNameMaxLength = 100;

/**
 * An array of all the blacklisted file extensions (without a preceding .)
 */
export const ExtensionBlacklist = [ 'exe', 'com', 'bat', 'sh', 'dmg', 'dll', 'jar' ];

/**
 * An array of all the blacklisted MIME types
 */
export const MimeTypeBlacklist = [
	'application/exe',
	'application/x-msdownload',
	'application/x-exe',
	'application/dos-exe',
	'application/x-winexe',
	'application/msdos-windows',
	'application/x-msdos-program',
	'application/vnd.microsoft.portable-executable',
	'application/java-archive',
	'application/x-apple-diskimage',
	'vms/exe',
	'application/x-sh',
];

/**
 * All possible file status states
 */
export enum Status {
	Uploading = 'uploading',
	Saved     = 'saved',
	Error     = 'error',
	Cancelled = 'cancelled',
}

/**
 * This tracks a File uploaded to the system.
 */
@Entity({ common : true })
@Permissions({
	read : [ orgMemberOrCollection, Permissions.stopChecks ],
})
export class File extends BaseOrgEntity {

	/**
	 * The type of document this file represents
	 */
	@Column({ length : 50, default : '' })
	@Permissions({ write : Permissions.serverOnly })
	documentType: DocumentType;

	/**
	 * The name (including extension) of the file
	 */
	@Column({ length : 100 })
	@Permissions({ write : Permissions.serverOnly })
	@Validate({ maxLength : 100,
		custom    : function() {
			return _.includes(ExtensionBlacklist, this.extension) ? 'This extension type is not permitted' : '';
		} })
	name: string;

	/**
	 * The original file name of the file
	 * @deprecated should be moved into the name field once the document type migration is completed
	 */
	@Alias('originalName')
	@Column({ name : 'originalName', length : originalNameMaxLength, nullable : true })
	private originalNameValue: string;

	get originalName() {
		return this.originalNameValue;
	}
	set originalName(name: string) {
		if (name) {
			const fileExtension    = name.substring(name.lastIndexOf('.'));
			this.originalNameValue = name.length <= originalNameMaxLength
				? name
				: `${name.substring(0, originalNameMaxLength - fileExtension.length)}${fileExtension}`;
		}
		else {
			this.originalNameValue = null;
		}
	}

	/**
	 * The size, in bytes, of the file
	 */
	@Column({ type : 'integer', transformer : BytesTransformer })
	@Permissions({ write : Permissions.serverOnly })
	size: Bytes;

	/**
	 * The MIME type of the file
	 */
	@Column({ length : 50 })
	@Permissions({ write : Permissions.serverOnly })
	@Validate({ custom : value => _.includes(MimeTypeBlacklist, value) ? 'This mime type is not permitted' : '' })
	mimeType: string;

	/**
	 * The status of the file (ie. whether it has been saved, is uploading, etc.)
	 */
	@Column({ length : 10, nullable : true })
	@Permissions({ write : Permissions.serverOnly })
	status: Status = null;

	/**
	 * any error message from upload
	 */
	@Column({ nullable : true })
	@Permissions({ write : Permissions.serverOnly })
	errorMessage: string = null;

	/**
	 * The entity that this file is in reference to.
	 * Format: <entity $class>/<entity id>
	 */
	// SHOULDDO: re-factor this with identical code in Comments into a mixin to share between both entities
	@Index()
	@Column({ length : 50 })
	reference: string = '';
	private referenceEntity: BaseEntity = null;

	constructor(initialValues?: Partial<File>) {
		super();
		this.size = new Bytes();
		if (initialValues) {
			_.assign(this, initialValues);
		}
	}

	/**
	 * The file's extension, without preceding dot (.)
	 * If the file does not have an extension, an empty string is returned
	 */
	get extension(): string {
		return File.getExtension(this.name);
	}

	get withoutExtension(): string {
		return File.getWithoutExtension(this.name);
	}

	/**
	 * true if this file's extension or mimetype indicate that this file is an image, false otherwise
	 */
	get isImage(): boolean {
		return File.isImage(this.name, this.mimeType);
	}

	/**
	 * The URL where the actual file's contents that this File entity represents can be retrieved
	 */
	get contentURL(): string {
		return File.collectionUrl(`${this.id}/content`);
	}

	get hasError() {
		return this.status === Status.Error;
	}

	setReference(newValue: string | BaseEntity) {
		if (newValue instanceof BaseEntity) {
			this.referenceEntity = newValue;
			if (newValue.isNew) {
				return;	// new entities don't yet have an id so wait until later
			}

			newValue = newValue.getReferenceString();
		}
		this.reference = newValue as string;
	}

	getReferenceClass(): typeof BaseEntity {
		return this.reference ? getEntityClass(this.reference.split('/', 2)[0]) : undefined;
	}

	getReferenceID(): string {
		return this.reference ? this.reference.split('/', 2)[1] : undefined;
	}

	/**
	 * Gets this File's content data.
	 */
	async getContents(): Promise<Buffer> {
		throw new Errors.NotImplemented();
	}

	/**
	 * Override save to set the reference field just before saving if not already set.
	 * Applies to Files that originally referenced a new entity.
	 */
	save(...args) {
		if (this.referenceEntity) {
			if (this.referenceEntity.isNew) {
				throw new Error('cannot save with a new referenced entity.  Save the referenced entity first');
			}
			this.setReference(this.referenceEntity);
		}

		return super.save.apply(this, args);
	}

	/**
	 * @constant
	 * The maximum allowable file size, in bytes
	 */
	static get maxFileSize() {
		return new Bytes(10, Unit.MebiByte);
	}

	/**
	 * Retrieves the 'extension' of the given filename.
	 * That is, the substring of filename after the last period (.) in the given filename.
	 * If the given filename does not have any periods in it, an empty string is returned.
	 * @param  {String} filename
	 */
	static getExtension(filename: string): string {
		if (!filename || typeof filename !== 'string') {
			return '';
		}

		const lastDotIndex = filename.lastIndexOf('.');
		return lastDotIndex >= 0 ? filename.substr(lastDotIndex + 1) : '';
	}

	/**
	 * Retrieves the filename without the extension.
	 * That is, the substring of filename before the last period (.) in the given filename.
	 * If the given filename does not have any periods in it, the filename is returned as is.
	 * @param  {String} filename
	 */
	static getWithoutExtension(filename: string): string {
		if (!filename || typeof filename !== 'string') {
			return filename;
		}

		const lastDotIndex = filename.lastIndexOf('.');
		return lastDotIndex >= 0 ? filename.substr(0, lastDotIndex) : '';
	}

	/**
	 * Determines if the given mimetype, or the extension of the given filename, indicate that it is an image.
	 * NOTE: mimetype check takes precedence over filename extension check.
	 * @param  {String}  filename   The filename of a file that may be an image
	 * @param  {String}  [mimetype] The MIME type of a file that may be an image
	 * @return {Boolean}            true if mimetype/filename indicate an image file, false otherwise.
	 */
	static isImage(filename: string, mimetype: string): boolean {
		if (mimetype && _.includes(ImageMimeTypes, mimetype)) {
			return true;
		}

		return _.includes(ImageExtensions, File.getExtension(filename));
	}

}

async function orgMemberOrCollection(context: Context, entity: File, query: SelectQueryBuilder<File>) {
	if (context.role.hasPermission(RolePermission.CrossOrgRead)) {
		return; // role can read any organization
	}

	if (query) {
		const alias       = query.expressionMap.mainAlias.name;
		let queryString   = `${alias}.orgId = :orgId`;
		const params: any = {
			orgId : context.org.id,
		};

		if (context.role.hasPermission(RolePermission.CollectFileCrossOrgAccess)) {
			query.innerJoin(Lease, 'lease', `${alias}.reference = CONCAT("Lease/", lease.id)`);
			queryString += ` OR (
				lease.reportingStatus->'$.type' = '${ReportingType.Collections}'
				AND lease.reportingStatus->'$.collectionsStatus' IN ('${CollectionsStatus.Submitted}', '${CollectionsStatus.Reported}')
			)`;
		}

		query.andWhere(`(${queryString})`, params);
	}

	else {
		if (context.role.hasPermission(RolePermission.CollectFileCrossOrgAccess)) {
			const lease = await Lease.findOne({ where : { id : entity.getReferenceID() } });
			if (lease?.collections && lease?.reportingStatus?.submitted) {
				return '';
			}
		}

		return isMyOrganization(context.org, entity);
	}
}
