import Axios                          from 'axios';
import Vue                            from 'vue';
import { FindConditions, ObjectType } from 'typeorm';
import { FindOptionsUtils }           from 'typeorm/find-options/FindOptionsUtils';

import Errors from '$/lib/Errors';
import { convertFindOperatorToQueryOperator, isFindOperator } from '$/lib/typeormExt';

import {
	BaseEntity as BaseEntityCommon, createEntity, FindManyOptions, FindOneOptions, EntityID, defaultFindLimit, getEntityID
}        from '$/common/entities/BaseEntity';
export * from '$/common/entities/BaseEntity';

/**
 * A set of all registered objects that may have properties that reference entities.
 * Whenever an entity is updated, scan the entire set and update any entities that match.
 * The map's keys are the containers and
 * the values are possible properties to check (if undefined, check all enumerable properties)
 */
const entityContainers = new Map<any, string[]>();

/**
 * Base class for all client-side entities.
 * Implements entity methods for communicating with the API.
 */
export class BaseEntity extends BaseEntityCommon {

	async save(): Promise<this> {
		if (!this.isNew) {
			if (!this.isEditable) {
				throw new Error('must call makeEditable() before trying to call save()');
			}
			if (!this.isEdited()) {
				return this;	// no changes made so skip actually saving anything
			}
		}

		const clazz = this.entityClass;
		let result;

		const pojo = clazz.toObject(this, { mustBeEdited : !this.isNew });

		if (this.id) {
			result = (await Axios.put(clazz.collectionUrl(this.id), pojo)).data;
		}
		else {
			result = (await Axios.post(clazz.collectionUrl(), pojo)).data;
			if (!result.id) {
				throw new Error('expected id in result');
			}
			this.id = result.id;
		}

		// refresh this entity since it's now been updated
		this.mergeColumns(result, { commonColumnsOnly : true });
		this.makeEditable({ force : true });
		updateEntityContainers(this, result);

		return this;
	}

	async reload({ includeRelations = [] } = {}): Promise<void> {
		const results = await super.reload.call(this, { includeRelations });
		updateEntityContainers(this, results);
	}

	// #region STATIC MEMBERS

	static create<T extends BaseEntity>(this: ObjectType<T>): T {
		return createEntity({ $class : this.name });
	}

	static async findOneRaw(optionsOrConditions?: string|any|FindOneOptions): Promise<Record<string, any> | undefined> {
		if (!optionsOrConditions) {
			optionsOrConditions = {};
		}

		if (typeof optionsOrConditions === 'object') {
			optionsOrConditions.limit = 1;
		}

		const params = processFindOptions(this, optionsOrConditions);

		return (await Axios.get(this.collectionUrl(), { params })).data.results[0];
	}

	/**
	 * Returns zero or one entity that match the given criteria.
	 */
	static async findOne<T extends typeof BaseEntity>(this: T, optionsOrConditions?: string|any|FindOneOptions<InstanceType<T>>): Promise<InstanceType<T>|undefined> {
		try {
			const result = await this.findOneRaw(optionsOrConditions);
			return result ? createEntity(result, { possibleClass : this, makeEditable : optionsOrConditions?.editable }) as InstanceType<T> : undefined;
		}
		catch (error) {
			if (error instanceof Errors.HTTP.NotFound || error.status === 404) {
				return undefined;
			}
			throw error;
		}
	}

	static async findRawAndCount(optionsOrConditions: FindManyOptions = {}): Promise<[ Record<string, any>[], number ]> {
		const { results, totalCount } = (await Axios.get(this.collectionUrl(), {
			params : processFindOptions(this, {
				...optionsOrConditions,
				limit : optionsOrConditions.limit > defaultFindLimit ? defaultFindLimit : optionsOrConditions.limit,
			}),
		})).data;
		let nextBatch = [];

		// if there has been some results but it hasn't reached the limit do another fetch
		// if the second fetch result is empty this cycle will stop
		if (results.length && optionsOrConditions.limit && results.length < optionsOrConditions.limit) {
			const remaining = optionsOrConditions.limit - results.length;
			nextBatch       = (await this.findRawAndCount({
				...optionsOrConditions,
				offset : (optionsOrConditions.offset || 0) + results.length,
				limit  : remaining,
			}))[0];
		}

		return [ [ ...results, ...nextBatch ], totalCount ];
	}

	/**
	 * Returns zero or more entities that match the given criteria.
	 */
	static async find<T extends typeof BaseEntity>(this: T, optionsOrConditions?: FindManyOptions<InstanceType<T>>): Promise<InstanceType<T>[]> {
		const cache = [];
		return (await this.findRaw(optionsOrConditions))
			.map(result => createEntity(result, { possibleClass : this, makeEditable : optionsOrConditions?.editable, cache }) as InstanceType<T>)
		;
	}

	static async findAndCount<T extends typeof BaseEntity>(this: T, optionsOrConditions?: FindManyOptions<InstanceType<T>>): Promise<[ InstanceType<T>[], number]> {
		const cache                 = [];
		const [ rawResults, count ] = await this.findRawAndCount(optionsOrConditions);
		return [
			rawResults.map(result => createEntity(result, { possibleClass : this, makeEditable : optionsOrConditions?.editable, cache }) as InstanceType<T>),
			count,
		];
	}

	static async count(optionsOrConditions?: FindManyOptions): Promise<number>;
	static async count(optionsOrConditions?: FindManyOptions & { countBy: string }): Promise<Record<string, number>>;
	static async count<T extends BaseEntity>(optionsOrConditions?: FindManyOptions<T> & { countBy?: keyof T }): Promise<number | Record<string, number>> {
		let params: URLSearchParams;

		if (typeof optionsOrConditions === 'object') {
			params = processFindOptions(this, _.omit(optionsOrConditions, 'countBy'));
			if (optionsOrConditions.countBy) {
				params.set('countBy', String(optionsOrConditions.countBy));
			}
		}
		else {
			params = processFindOptions(this, optionsOrConditions);
		}

		return (await Axios.get(this.collectionUrl('/count'), { params })).data as number;
	}

	/**
	 * Soft-deletes the given entity or by entity id.
	 */
	static async archive(entities: PossibleArray<BaseEntity | EntityID>) {
		for (const entity of _.castArray(entities)) {
			const id = getEntityID(entity);
			if (!id || entity instanceof BaseEntity && entity.isNew) {
				continue;  // nothing needs to be done
			}
			// COULDDO PERFORMANCE: figure out how to archive multiple ids in one call
			await Axios.delete(this.collectionUrl(id));
			updateEntityContainers(entity instanceof BaseEntity ? entity : { id, constructor : this });
		}
	}

	static async remove(entities: PossibleArray<BaseEntity | EntityID>): Promise<any | any[]> {
		for (const entity of _.castArray(entities)) {
			const id = getEntityID(entity);
			if (!id || entity instanceof BaseEntity && entity.isNew) {
				continue;  // nothing needs to be done
			}
			// COULDDO PERFORMANCE: figure out how to remove multiple ids in one call
			await Axios.delete(this.collectionUrl(id), { params : { hard : true } });
			updateEntityContainers(entity instanceof BaseEntity ? entity : { id, constructor : this });
		}
		return [];
	}

	// #endregion STATIC MEMBERS

}

/**
 * Decorator that searches for entities in the Vue component and keeps them up-to-date.
 * Use on class properties of type BaseEntity or on an entire class (which will then search all properties
 * of the class).  Using this on specific property decorators is more efficient.
 */
export function SyncEntities() {
	return function(componentClass, property?: string) {
		const prototype = typeof componentClass === 'function' ? componentClass.prototype : componentClass;

		// check if it's a Vue component
		if (prototype instanceof Vue) {
			const oldCreated = prototype.created;

			if (oldCreated) {
				prototype.created = function() {
					addProperty(this, property);
					return oldCreated.apply(this, arguments);	// eslint-disable-line prefer-rest-params
				};
			}
			else {
				prototype.created = function() {
					addProperty(this, property);
				};
			}
		}
		// otherwise treat as a regular class
		else {
			addProperty(componentClass, property);
		}
	};

	function addProperty(container, property) {
		let array = entityContainers.get(container);
		if (property) {
			if (array === undefined) {
				array = [ ];
			}
			array.push(property);
		}

		entityContainers.set(container, array);
	}
}

// HELPER FUNCTIONS

function processFindOptions<T extends BaseEntity>(entityClass: typeof BaseEntity, optionsOrConditions?: FindManyOptions<T> | FindConditions<T> | EntityID) {
	if (optionsOrConditions === undefined || optionsOrConditions === null) {
		optionsOrConditions = {};
	}
	if (typeof optionsOrConditions === 'string') {
		optionsOrConditions = { where : { id : optionsOrConditions } };
	}
	if (typeof optionsOrConditions === 'object') {
		if (!FindOptionsUtils.isFindManyOptions(optionsOrConditions)) {
			optionsOrConditions = { where : optionsOrConditions };
		}
	}
	else {
		throw new Error('unknown param type for find');
	}

	// @ts-ignore ignore weird "Excessive stack depth comparing types" error
	if (!optionsOrConditions.where) {
		optionsOrConditions.where = {};
	}

	if (!(optionsOrConditions.where as any).$class) {
		(optionsOrConditions.where as any).$class = entityClass.name;
	}

	const params: any = {};
	params.where      = JSON.stringify(processWhere(optionsOrConditions.where));
	if (optionsOrConditions.relations) {
		params.relations = _.castArray(optionsOrConditions.relations).join(',');
	}
	if (!_.isNil((optionsOrConditions as FindManyOptions<T>).limit)) {
		params.limit = (optionsOrConditions as FindManyOptions<T>).limit;
	}
	if (!_.isNil((optionsOrConditions as FindManyOptions<T>).offset)) {
		params.offset = (optionsOrConditions as FindManyOptions<T>).offset;
	}
	if (optionsOrConditions.order) {
		params.order = _.map(optionsOrConditions.order, (dir, prop) => `${typeof dir === 'string' && dir.toLowerCase() === 'desc' ? '-' : '+'}${prop}`).join(',');
	}
	if (optionsOrConditions.select) {
		params.select = _.castArray(optionsOrConditions.select).join(',');
	}
	if (optionsOrConditions.withDeleted) {
		params.withDeleted = 'true';
	}
	if ((optionsOrConditions as FindManyOptions<T>).withDeletedRelations) {
		params.withDeletedRelations = 'true';
	}

	return new URLSearchParams(_.compactObject(params));
}

// replaces any references to Entity with the entity's ID and converts FindOperators to QueryOperators
export function processWhere(obj) {
	for (const property of Object.keys(obj)) {
		const value = obj[property];
		if (value instanceof BaseEntity) {
			obj[property] = value.id;
		}
		else if (isFindOperator(value)) {
			obj[property] = convertFindOperatorToQueryOperator(value);
		}
		else if (value && value.constructor === Object) {
			processWhere(value);
		}
		else if (Array.isArray(value)) {
			value.forEach(processWhere);
		}
	}

	return obj;
}

function updateEntityContainers(source: BaseEntity | { id: string; constructor: typeof BaseEntity }, newData?: any) {
	const id    = source.id;
	const ver   = source instanceof BaseEntity ? source.ver : undefined;
	const clazz = source.constructor as typeof BaseEntity;

	const entitiesToReload   = new Set<BaseEntity>();
	const containersToRemove = new Set();

	entityContainers.forEach((properties, container) => {
		// check if a container is a deleted Vue instance
		if (container instanceof Vue && container._isDestroyed) {
			containersToRemove.add(container);
			return;
		}

		if (!properties) {
			properties = Object.getOwnPropertyNames(container);
		}

		properties.forEach(property => {
			_.castArray(container[property]).forEach(possibleEntity => {
				if (possibleEntity instanceof BaseEntity
					&& possibleEntity.id === id
					&& possibleEntity.constructor === clazz
					&& possibleEntity.ver !== ver
				) {
					if (newData) {
						possibleEntity.mergeColumns(newData);
					}
					else {
						entitiesToReload.add(possibleEntity);
					}
				}
			});
		});
	});

	// SHOULDDO PERFORMANCE: de-dupe entities into the same entity/id to avoid fetching the same entity more than once
	entitiesToReload.forEach(entity => entity.reload());

	containersToRemove.forEach(container => entityContainers.delete(container));
}
