import { Field, JSONable } from '$/entities/lib/JSONable';

export enum VerificationType {
	Kount  = 'kount',
	Upload = 'Upload',
}
export class VerificationResult extends JSONable {

	constructor(type: VerificationType, passed: boolean, score?: number, reasons?: string[], additionalData?: any) {
		super();
		this.date           = new Date();
		this.type           = type;
		this.passed         = passed;
		this.score          = score;
		this.reasons        = reasons;
		this.additionalData = additionalData;
	}

	@Field()
	type: VerificationType;

	@Field()
	date: Date;

	@Field()
	passed: boolean;

	@Field()
	score?: number;

	@Field()
	reasons?: string[];

	@Field()
	invalid?: boolean;

	@Field()
	additionalData?: any;

}
