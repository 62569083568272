import Moment                           from 'moment';
import { Country }                      from '$/lib/Address';
import { DayTransformer }               from '$/lib/columnTransformers';
import Errors                           from '$/lib/Errors';
import { AccountStatus, Metro2Segment } from '$/lib/Metro2';
import { SelectQueryBuilder }           from '$/lib/typeormExt';

import Permissions, { Context }                     from '$/entities/lib/Permissions';
import { BaseEntity, CommonEntity, getEntityClass } from '$/entities/BaseEntity';
import { RolePermission }                           from '$/entities/roles/RolePermission';
import { Column, ManyToOne }                        from '$/entities/BaseEntity';
import { LeaseBalance }                             from '$/entities/LeaseBalance';
import type { Tenant }                              from '$/entities/Tenant';

export enum CreditBureau {
	EquifaxCA  = 'equifaxCA',
	EquifaxUS  = 'equifaxUS',
	LcbCA      = 'lcbCA',
	LcbUS      = 'lcbUS',
	ExperianUS = 'experianUS',
}

export enum Metro2Status {
	Uploaded = 'uploaded',    // Metro2Report sent to all credit bureaus.
	Success  = 'success',     // processing succeeded
	Failure  = 'failure',     // processing throw an unexpected error (reason field contains the error)
	Skipped  = 'skipped',     // tenant report could not be generated (reason field contains more info)
}

export enum ReportingGroup {
	Tradeline  = 'tradeline',
	Collection = 'collection',
}

export enum Metro2Reasons {
	FirstMonth                  = 'lease did not exist last month',
	FutureTenant                = 'tenant was not on lease last month',
	NoConsent                   = 'renter has not consented to be reported',
	Archived                    = 'tenant is archived',
	Excluded                    = 'tenant is excluded',
	Old                         = "tenant's lease's end date is too old",
	Suspended                   = 'landlord organization is suspended',
	Inactive                    = 'landlord organization is inactive',
	NotVerified                 = 'landlord organization is not verified',
	Country                     = 'tenant not in supported country',
	MissingField                = 'tenant record is missing date of birth',
	EquifaxDisabled             = "the current lease does not have the 'Rent Reporting' toggle enabled",
	DebtReportingDisabled       = "the former lease does not have the 'Debt Reporting' toggle enabled",
	EquifaxFeature              = "neither landlord org nor the renter have the 'Rent Reporting' feature",
	DebtReportingFeature        = "landlord org does not have the 'Debt Reporting' feature",
	RenterIdentityNotVerified   = 'renter has signed up but not verified their identity',
	RenterSuspended             = 'renter is suspended',
	WithdrawnConsent            = 'renter has withdrawn consent',
	NotReporting                = 'not reporting',
	DoNotEngage                 = 'tenant is in the "do not engage" list',
	PlacedInError               = 'collections was placed in error',
	DataContributorAgreement    = 'landlord has not yet agreed to the latest data contributor agreement',
	PositiveReportingOnly       = 'landlord organization is configured to only report positive information to credit bureaus',
	CommercialTenant            = 'reporting of commercial tenants is not currently supported',
	GracePeriod                 = 'tenant is in the grace period before debts can be reported',
	MissingData                 = 'no lease balance amount was entered this month',
	SubmittedToCollections      = 'tenant has been submitted to an external collections agency',
	CannotReopen                = 'cannot reopen reporting once it has been reported as closed',
	ReportingGroupNotApplicable = 'reporting group is not applicable for this tenant',
	NullBalance                 = 'a value was not provided for the balance this month',
	BalanceNotUpdated           = 'the balance has not been updated for several months in a row',
	InvalidAddress              = 'the building does not have a valid address',
	MinimumAmount               = 'small amounts of debt cannot be reported to this bureau',
	ReportingAfterFinalized     = "reporting was not enabled until after this month's data was finalized",
	// legacy reasons. DO NOT USE
	CollectEnabled              = "the former lease does not have the 'Collect' toggle enabled",
	CollectFeature              = "landlord org does not have the 'ReportToCollections' feature",
}

const HumanReadableReasonMapping = {
	ReportedDebtOwed : {
		status  : 'Reported Debt Owed',
		tooltip : 'A debt has been recorded for the indicated month and it has been reported to Credit Bureaus.',
	},
	ReportedAndClosedInGoodStanding : {
		status  : 'Reported and Closed In Good Standing',
		tooltip : 'Rent for the indicated month has been reported as paid in full to Credit Bureaus and the tradeline was successfully closed. The account for this tenant is now no longer active and will show no outstanding balance.',
	},
	RenterHasBeenDeleted : {
		status  : 'Renter Has Been Deleted',
		tooltip : "The Tenants' entire record with the Credit Bureaus has been successfully deleted.",
	},
	RenterHasBeenExcluded : {
		status  : 'Renter Has Been Excluded',
		tooltip : 'The Tenants has been excluded from reporting. Any prior reporting record with Credit Bureaus is deleted.',
	},
	ReportedInGoodStanding : {
		status  : 'Reported In Good Standing',
		tooltip : 'Rent for the indicated month has been reported as paid in full to Credit Bureaus.',
	},
	RenterDidNotExistLastMonth : {
		status  : 'Renter Did Not Exist Last Month',
		tooltip : 'This record did not exist in your Lease Records for the indicated month, therefore it has not been reported to Credit Bureaus. Please ensure Rent Reporting or Debt Reporting remains on and all relevant fields are filled out so next month the reporting can occur.',
	},
	RenterNotOnLeaseLastMonth : {
		status  : 'Renter Was Not On Lease Last Month',
		tooltip : 'Tenant was not active in the indicated month, therefore it has not been reported to Credit Bureaus. Please ensure Rent Reporting or Debt Reporting remains on and all relevant fields are filled out so next month the reporting can occur.',
	},
	RenterDidNotConsent : {
		status  : 'Renter Did Not Consent',
		tooltip : 'If there is not a debt owed, the tenant must opt-in by logging in and clicking the consent box in their Lease Record. Therefore it has not been reported to Credit Bureaus for the indicated month. Remind your Tenant they need to opt-in for the benefits by providing consent on their Lease Record.',
	},
	TenancyDateIsTooOld : {
		status  : 'Tenancy Date is Too Old',
		tooltip : 'No information was shared with Credit Bureaus because rental information is older than 6 years in Canada or 7 years in the US. If this is a mistake, please update the move out date on the Lease Record.',
	},
	LandlordAccountInactive : {
		status  : 'Landlord Account Inactive',
		tooltip : 'No information was shared with Credit Bureaus as the Landlord had not logged in for 60 days to keep their account active. If you get this message please make sure you are logging into your account at least every 60 days so Rent Reporting can occur successfully.',
	},
	LandlordAccountNotVerified : {
		status  : 'Landlord Account Not Verified',
		tooltip : 'No information was reported to Credit Bureaus because you have not completed ID or role verification. To complete your ID verification, click on Complete My Profile under the actions required on your dashboard page. Please follow the steps to verify your ID and role in FrontLobby.',
	},
	UnsupportedCountry : {
		status : 'Unsupported Country',
	},
	MissingDateOfBirthSSNOrSIN : {
		status  : 'Missing Date of Birth, SSN or SIN',
		tooltip : 'Rent for the indicated month was not reported to Credit Bureaus because there is no date of birth, SIN or SSN for the Tenant. To fix this, click on the Tenants\' name under the My Leases tab. Add the date of birth, SIN or SSN to their record and click save. Alternatively, you can have the Tenant sign in, complete their profile and add these details themselves to benefit from rent reporting.',
	},
	CreditReportingDisabled : {
		status  : 'Credit Reporting Disabled',
		tooltip : 'The Rent Reporting feature is not turned on, therefore it has not been reported to Credit Bureaus. To turn this feature on, please find their Lease Record under the My Leases tab and turn on the Rent Reporting toggle.',
	},
	DebtReportingFeatureDisabled : {
		status  : 'Debt Reporting Feature Disabled',
		tooltip : 'The Debt Reporting feature is not turned on, therefore it has not been reported to Credit Bureaus. To turn this feature on, please find their Lease Record under the My Leases tab and turn on the Debt Reporting toggle.',
	},
	RenterAccountSuspended : {
		status  : 'Renter Account Suspended',
		tooltip : 'No information was reported to Credit Bureaus because the Tenants\' account has been suspended. If you receive this message you may reach out to support for more details.',
	},
	RenterWithdrewConsent : {
		status  : 'Renter Withdrew Consent',
		tooltip : 'If there is not a debt owed, the tenant must opt-in by logging in and clicking the consent box in their Lease Record. Therefore it has not been reported to Credit Bureaus for the indicated month. Remind your Tenant they need to opt-in for the benefits by providing consent on their Lease Record.',
	},
	NothingToReport : {
		status  : 'Nothing To Report',
		tooltip : 'No information was shared with Credit Bureaus because there is incomplete information or no information to report on the Tenant. To fix this, go to the My Leases tab and click on the Tenants\' name. Complete all the required fields including date of birth, SIN or SSN and click save.',
	},
	CommercialTenant : {
		status  : 'Commercial Tenant Not Supported',
		tooltip : 'Reporting of Commercial tenants to credit bureaus is not currently supported.',
	},
	CannotReopen : {
		status  : 'Cannot Reopen Reporting',
		tooltip : "Once a non-consenting tenant's debt has been reported as closed, it cannot be reopened. This is a requirement of some credit bureaus.",
	},
};

const HumanReadableReason = {
	[AccountStatus.SeriouslyPastDue]          : HumanReadableReasonMapping.ReportedDebtOwed,
	[AccountStatus.PastDue180Days]            : HumanReadableReasonMapping.ReportedDebtOwed,
	[AccountStatus.PastDue150Days]            : HumanReadableReasonMapping.ReportedDebtOwed,
	[AccountStatus.PastDue120Days]            : HumanReadableReasonMapping.ReportedDebtOwed,
	[AccountStatus.PastDue90Days]             : HumanReadableReasonMapping.ReportedDebtOwed,
	[AccountStatus.PastDue60Days]             : HumanReadableReasonMapping.ReportedDebtOwed,
	[AccountStatus.PastDue30Days]             : HumanReadableReasonMapping.ReportedDebtOwed,
	[AccountStatus.PaidOrClosedAccount]       : HumanReadableReasonMapping.ReportedAndClosedInGoodStanding,
	[AccountStatus.DeleteEntireAccount]       : HumanReadableReasonMapping.RenterHasBeenDeleted,
	[AccountStatus.AccountInGoodStanding]     : HumanReadableReasonMapping.ReportedInGoodStanding,
	[Metro2Reasons.FirstMonth]                : HumanReadableReasonMapping.RenterDidNotExistLastMonth,
	[Metro2Reasons.FutureTenant]              : HumanReadableReasonMapping.RenterNotOnLeaseLastMonth,
	[Metro2Reasons.NoConsent]                 : HumanReadableReasonMapping.RenterDidNotConsent,
	[Metro2Reasons.Archived]                  : HumanReadableReasonMapping.RenterHasBeenDeleted,
	[Metro2Reasons.Excluded]                  : HumanReadableReasonMapping.RenterHasBeenExcluded,
	[Metro2Reasons.Old]                       : HumanReadableReasonMapping.TenancyDateIsTooOld,
	[Metro2Reasons.Suspended]                 : HumanReadableReasonMapping.LandlordAccountInactive,
	[Metro2Reasons.Inactive]                  : HumanReadableReasonMapping.LandlordAccountInactive,
	[Metro2Reasons.NotVerified]               : HumanReadableReasonMapping.LandlordAccountNotVerified,
	[Metro2Reasons.Country]                   : HumanReadableReasonMapping.UnsupportedCountry,
	[Metro2Reasons.MissingField]              : HumanReadableReasonMapping.MissingDateOfBirthSSNOrSIN,
	[Metro2Reasons.EquifaxDisabled]           : HumanReadableReasonMapping.CreditReportingDisabled,
	[Metro2Reasons.DebtReportingDisabled]     : HumanReadableReasonMapping.DebtReportingFeatureDisabled,
	[Metro2Reasons.EquifaxFeature]            : HumanReadableReasonMapping.CreditReportingDisabled,
	[Metro2Reasons.DebtReportingFeature]      : HumanReadableReasonMapping.DebtReportingFeatureDisabled,
	[Metro2Reasons.RenterIdentityNotVerified] : HumanReadableReasonMapping.RenterDidNotConsent,
	[Metro2Reasons.RenterSuspended]           : HumanReadableReasonMapping.RenterAccountSuspended,
	[Metro2Reasons.WithdrawnConsent]          : HumanReadableReasonMapping.RenterWithdrewConsent,
	[Metro2Reasons.DoNotEngage]               : HumanReadableReasonMapping.RenterDidNotConsent,
	// Legacy Reasons DO NO USE
	[Metro2Reasons.CollectEnabled]            : HumanReadableReasonMapping.DebtReportingFeatureDisabled,
	[Metro2Reasons.CollectFeature]            : HumanReadableReasonMapping.DebtReportingFeatureDisabled,
};

/**
 * This entity records the history of Metro2 results for a tenant.
 */
@CommonEntity()
@Permissions({
	create : Permissions.serverOnly,
	read   : associatedTenantOrPermission,
	update : Permissions.serverOnly,
	delete : Permissions.serverOnly,
})
export class TenantMetro2 extends BaseEntity {

	@ManyToOne('Tenant', { nullable : false })
	tenant: Tenant = undefined;

	/**
	 * The month that this Metro2 segment reports on. (usually prior month to this.createdOn)
	 * The day should always be set to 1st of the month.
	 */
	@Column({ type : 'date', transformer : DayTransformer })
	month: Date = null;

	/**
	 * The type of result
	 */
	@Column()
	status: Metro2Status = '' as Metro2Status;

	/**
	 * The Metro2 segment for this tenant if the processing succeeded.
	 */
	// MUST DO use JSONABLE here to correctly serialize/deserialize
	@Column({ type : 'json', nullable : true })
	segment: Metro2Segment;

	/**
	 * An description message if the processing did not yield a segment.
	 */
	@Column({ default : '' })
	reason: string = '';

	/**
	 * TenantMetro2 are included in different groups which are used to generate different Metro2 files even for the same bureau and same month.
	 */
	@Column({ default : ReportingGroup.Tradeline, nullable : false })
	group: ReportingGroup = ReportingGroup.Tradeline;

	constructor();
	constructor(tenant: Tenant,  status: Metro2Status.Success, reason: string, segments?: Metro2Segment);
	constructor(tenant: Tenant,  status: Metro2Status.Failure, reason: Error|string);
	constructor(tenant: Tenant,  status: Metro2Status.Skipped, reason: string);
	constructor(tenant?: Tenant, status?: Metro2Status, reason?: string|Error, segments?: Metro2Segment) {
		super();

		if (tenant) {
			this.tenant = tenant;
		}

		if (status !== undefined) {
			this.status = status;
		}
		if (segments instanceof Metro2Segment) {
			this.segment = segments;
		}

		if (reason instanceof Error) {
			this.reason = reason.message || String(reason);
		}
		else if (typeof reason === 'string') {
			this.reason = reason;
		}

		this.month = Moment().startOf('month').subtract(1, 'month').toDate();
	}

	get customerFacingReason(): { status: string; tooltip?: string } {
		switch (this.status) {
			case Metro2Status.Success:
			case Metro2Status.Uploaded:
				return HumanReadableReason[this.segment.base.accountStatus];
			case Metro2Status.Skipped:
				return HumanReadableReason[this.reason] ?? { status : '' };
			case Metro2Status.Failure:
			default:
				return { status : 'Error' };
		}
	}

	async getAssociatedLeaseBalance() {
		await this.loadRelation('tenant');
		return LeaseBalance.findOne({ where : { leaseId : (this.tenant as any).leaseId, month : this.month } });
	}

	/**
	 * @returns the credit bureau associated with this class
	 */
	static getCreditBureau(): CreditBureau {
		throw new Errors.NotImplemented();
	}

	/**
	 * @returns the country associated with the credit bureau
	 */
	static getCreditBureauCountry(): Country {
		switch (this.getCreditBureau()) {
			case CreditBureau.EquifaxCA:
			case CreditBureau.LcbCA:
				return Country.CA;
			case CreditBureau.EquifaxUS:
			case CreditBureau.LcbUS:
			case CreditBureau.ExperianUS:
				return Country.US;
			default:
				throw new Error(`unknown credit bureau: ${this.getCreditBureau()}`);
		}
	}

	/**
	 * @returns true if the given Metro2 header segment belongs to this class
	 */
	static getReportingGroup(programID: string): ReportingGroup | void {			// eslint-disable-line @typescript-eslint/no-unused-vars
	}

	/**
	 * The first month for which to expose records to users.
	 */
	static get minMonth() {
		return new Date(2021, 7, 1);
	}

	static getSample() {
		return new this((getEntityClass('Tenant') as unknown as typeof Tenant).getSample(), Metro2Status.Skipped, '');
	}

}

async function associatedTenantOrPermission(context: Context, tenantMetro2: TenantMetro2, query: SelectQueryBuilder<TenantMetro2>): Promise<string> {
	if (context.role.hasPermission(RolePermission.Metro2Read)) {
		return '';
	}

	if (query) {
		const alias = query.expressionMap.mainAlias.name;

		query.innerJoin('tenant', 'tenant', `tenant.id = \`${alias}\`.tenantId`);
		query.innerJoin('lease', 'lease', 'lease.id = tenant.leaseId');
		query.andWhere(`lease.orgId = :orgID AND \`${alias}\`.month >= :minMonth`, { orgID : context.org.id, minMonth : TenantMetro2.minMonth });

		return;
	}

	await tenantMetro2.loadRelation('tenant.lease.building');
	if (tenantMetro2.tenant.lease.orgId === context.org.id && Moment(tenantMetro2.month).isSameOrAfter(Moment(TenantMetro2.minMonth))) {
		return;
	}

	return 'insufficient permission';
}
