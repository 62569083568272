/**
 * Library to compose the Metro2 Credit Reporting Format.
 */
import 'reflect-metadata';
import Map2                             from '$/lib/Map2';
import { Mixin }                        from '$/lib/utils';
import { Field as JsonField, JSONable } from '$/entities/lib/JSONable';

// allows for syntactic sugar during @Field definitions
const required = true;

type ValidationFunction = (value: any) => boolean;
type RequiredFunction   = (value: any) => boolean;


export class FieldDesc {

	fieldNumber: string;
	name: string;
	startIndex: number;
	endIndex: number;
	required: RequiredFunction;
	validation: ValidationFunction;
	deburr: boolean;
	dataType: Class;

	get length() {
		return this.endIndex - this.startIndex + 1;
	}

	constructor(
		clazz: Class, name: string, fieldNumber: string, startIndex: number, endIndex: number,
		required: RequiredFunction, validation: ValidationFunction, deburr: boolean, dataType?: Class
	) {
		this.fieldNumber = fieldNumber;
		this.name        = name;
		this.startIndex  = startIndex;
		this.endIndex    = endIndex;
		this.required    = required;
		this.validation  = validation;
		this.deburr      = deburr;
		this.dataType    = dataType ?? Reflect.getMetadata('design:type', clazz.prototype, name);

		if (!this.dataType) {
			throw new Error(`unknown data type "${this.dataType}" for field: ${this.name}`);
		}

		if (_.isNil(this.startIndex) || _.isNil(this.endIndex)) {
			throw new Error(`startIndex and endIndex cannot be nil, field: ${this.name}`);
		}

		if (this.startIndex > this.endIndex) {
			throw new Error(`startIndex cannot be greater than endIndex, field: ${this.name}`);
		}

		// check for conflicts with other fields in this class
		// SHOULDDO: check for conflicts with all fields in the Record
		const otherFields = FieldDesc.fields.get(clazz);
		if (otherFields) {
			this.checkForIndexConflicts(Array.from(otherFields.values()));
		}

		FieldDesc.fields.set(clazz, name, this);
	}

	/**
	 * Checks whether the given index value lies inside this field index range.
	 */
	isInIndex(value: number) {
		return this.startIndex <= value && value <= this.endIndex;
	}

	/**
	 * Asserts that startIndex & endIndex don't overlap another field by mistake.
	 */
	checkForIndexConflicts(otherFields: FieldDesc[]) {
		const conflictField = otherFields.find(field => field.isInIndex(this.startIndex) || field.isInIndex(this.endIndex));
		if (conflictField) {
			throw new Error(`two or more fields overlap in index: "${this.name}" and "${conflictField.name}"`);
		}
	}

	checkForRequired(value: any, record: RecordOrSegment) {
		if ((value === null || value === undefined || value === '') && this.required?.call(record, value)) {
			throw new Error(`required field is missing value: ${this.name}`);
		}
	}

	getString(value: any, record: RecordOrSegment): string {
		this.checkForRequired(value, record);

		const method = `as${this.dataType.name}`;
		if (typeof this[method] !== 'function') {
			throw new Error(`dataType is invalid for field: ${this.name} (${method})`);
		}
		value = this[method](value);

		if (typeof value !== 'string') {
			throw new Error(`expected value to be a string, instead got: ${value}`);
		}

		if (value.length !== this.length) {
			throw new Error(`length of ${this.length} for field ${this.name} does not match value length: ${value.length} (${value})`);
		}

		if (this.validation?.call(record, value) === false) {
			throw new Error(`validation failed for field "${this.name}" with value: ${value}`);
		}

		if (this.deburr) {
			return _.deburr(value);
		}

		return value;
	}

	protected asString(value: any) {
		if (_.isNil(value)) {
			value = '';
		}
		if (typeof value !== 'string') {
			value = String(value);
		}

		if (/[\r\n]/.test(value as string)) {
			throw new Error(`string value cannot include newlines for field: ${this.name}`);
		}

		// Every alpha field should be upper case letters
		// Every alpha numeric field is left justified and blank filled.
		return value.toUpperCase().padEnd(this.length, ' ').substr(0, this.length);
	}

	protected asDate(value: any) {
		if (_.isNil(value) || value === '') {
			return '0'.repeat(this.length);
		}

		if (typeof value === 'string') {
			value = new Date(value);
		}

		if (!(value instanceof Date)) {
			throw new Error(`field "${this.name}" has an invalid Date value: ${value}`);
		}
		// Format for Date Fields is MMDDYYYY
		return (value.getUTCMonth() + 1).toString().padStart(2, '0') + value.getUTCDate().toString().padStart(2, '0') + value.getUTCFullYear();
	}

	protected asNumber(value: any) {
		const origValue = value;

		if (_.isNil(value)) {
			value = 0;
		}

		// Every numeric field is right-justified and zero filled. If not available, it should be zero filled
		if (typeof value !== 'number') {
			value = Number(value);
		}

		if (isNaN(value)) {
			throw new Error(`value of field "${this.name}" cannot be converted to a number: ${origValue}`);
		}

		// negative numbers are not allowed
		if (value < 0) {
			throw new Error(`value of field "${this.name}" cannot be negative`);
		}

		return value.toString().padStart(this.length, '0');
	}

	protected asAmount(value: any) {
		return this.asNumber(new Amount(value).valueOf());
	}

	// all FieldDesc instances keyed by class and property name
	static fields: Map2<Class, string, FieldDesc> = new Map2();

	/**
	 * @returns the FieldDesc for the given property of this class or undefined if it does not exist
	 */
	static getFieldDesc(clazz: Class): FieldDesc[];
	static getFieldDesc(clazz: Class, property: string): FieldDesc;
	static getFieldDesc(clazz: Class, property?: string): PossibleArray<FieldDesc> {
		return property
			? this.fields.get(clazz, property)
			: Array.from((this.fields.get(clazz) || new Map<string, FieldDesc>()).values())
		;
	}

}

/**
 * Property decorator for a field. Also marks the property as a field for JSON serialization.
 * If all parameters are omitted, will only be marked for JSON serialization, not for metro2 report serialization.
 * @param {number|string} fieldNumber the 0-based number of the field (as corresponding to the Metro2 spec)
 * @param {[number,number]} characterPosition
 *            [0] the 1-based index of the first character of the field in the final output line
 *            [1] the 1-based index of the last character of the field in the final output line
 * @param {Object}  [options]
 * @param {RequiredFunction|Boolean}  [options.require]    if true, this field's value cannot be empty
 * @param {ValidationFunction|RegExp} [options.validation] if supplied, tests the final value against this validation function or RegExp
 */
export function Field();
export function Field(fieldNumber: number|string, characterPosition: [ number, number], options?: { required?; validation?; deburr? });
export function Field(fieldNumber?: number|string, characterPosition?: [ number, number], { required = undefined, validation = undefined, deburr = undefined } = {}) {
	return function(clazz, property: string) {
		if (fieldNumber !== undefined && characterPosition !== undefined) {
			if (validation instanceof RegExp) {
				validation = ((regexp: RegExp, value) => regexp.test(value)).bind(null, validation);
			}
			if (typeof required === 'boolean') {
				required = required ? (() => true) : (() => false) as RequiredFunction;
			}

			new FieldDesc(clazz.constructor, property, String(fieldNumber), characterPosition[0], characterPosition[1], required, validation, deburr);
		}

		JsonField().call(this, clazz, property);
	};
}

/**
 * Represents a monetary amount.
 * Fractional values are rounded off to the nearest whole integer.
 */
@Mixin(JSONable)
export class Amount {

	@Field()
	private _value: number = 0;

	constructor(newValue?: number | Amount) {
		if (Number.isNaN(newValue)) {
			throw new Error('value cannot be NaN');
		}

		this._value = newValue instanceof Amount ? newValue.valueOf() : Number(newValue ?? 0);
	}

	valueOf() {
		return this._value ? Math.round(this._value) : 0;
	}

}

/**
 * Base class which has utility functions shared between Records and Segments.
 */
abstract class RecordOrSegment {

	/**
	 * @returns the total number of characters in this record or segment
	 */
	abstract get length(): number;

	/**
	 * Checks all required fields for empty values.
	 */
	checkForRequiredFields() {
		this.forEachField((field: FieldDesc, value) => {
			field.checkForRequired(value, this);
		});
	}

	/**
	 * Serializes this record according to the Metro2 format.
	 */
	getString(): string {
		let result = ' '.repeat(this.length);

		this.forEachField((field: FieldDesc, value) => {
			result = result.substring(0, field.startIndex - 1) + field.getString(value, this) + result.substring(field.endIndex);
		});

		// sanity check
		if (result.length !== this.length) {
			throw new Error(`result of getString() has invalid length, expected ${this.length} but got: ${result.length}`);
		}

		return result;
	}

	/**
	 * Populates the fields of this record with properties of the given object.  Works recursively.
	 */
	loadFrom(obj: Object) {
		this.forEachField(obj, (field: FieldDesc, value, dest) => {
			if (value !== null && value !== undefined) {
				switch (field.dataType) {
					case Date:
						value = new Date(value);
						break;
					case Amount:
						value = new Amount(value._value ?? value);
						break;
				}
			}
			dest[field.name] = value;
		});
		return this;
	}

	/**
	 * Walks through all of the fields (recursively) and calls the callback for each Field and corresponding obj value.
	 */
	forEachField(callback: (field: FieldDesc, value, dest?) => void);
	forEachField(obj, callback: (field: FieldDesc, value, dest?) => void);
	forEachField(...args) {
		let obj, callback;
		if (args.length === 1) {
			obj      = this;		/* eslint-disable-line @typescript-eslint/no-this-alias */
			callback = args[0];
		}
		else if (args.length === 2) {
			obj      = args[0];
			callback = args[1];
		}
		else {
			throw new Error(`invalid number of arguments: ${args.length}`);
		}

		const fields: FieldDesc[] = [];
		const objects             = [ { clazz : this.constructor, src : obj, dest : this } ];

		while (objects.length > 0) {
			const { src, dest, clazz } = objects[0];
			const classes              = [ clazz, Object.getPrototypeOf(clazz) ];

			const classProperties = _.uniq([
				...Object.keys(src),
				// all @Fields for this class and parent class to make sure we haven't missed any fields...
				..._.flatMap(classes, clazz => FieldDesc.getFieldDesc(clazz).map(fieldDesc => fieldDesc.name)),
			]);

			for (const property of classProperties) {
				const value = src[property];
				const field = _(classes).map(clazz => FieldDesc.fields.get(clazz, property)).compact().first();

				if (field) {
					// check if field overlaps with previous fields
					field.checkForIndexConflicts(fields);
					fields.push(field);
					callback(field, value, dest);
				}
				else if (value && typeof value === 'object') {
					objects.push({ clazz : this[property].constructor, src : value, dest : this[property] });
				}
			}

			objects.shift();
		}
	}

	/**
	 * Static version
	 */
	static loadFrom(obj: Object) {
		// @ts-ignore TS doesn't like constructing an abstract type but "this" will be a concrete subclass
		const record = new this();
		record.loadFrom(obj);
		return record;
	}

}

/**
 * Represents a Metro2 report structure.
 */
export default class Metro2 {

	header: Header            = new Header();
	segments: Metro2Segment[] = [];
	trailer: Trailer          = new Trailer();

	finalizeTrailer() {
		this.trailer.setTotals(this.segments);
	}

	getString() {
		this.finalizeTrailer();

		return [
			this.header.getString(),
			...this.segments.map(segment => segment.getString()),
			this.trailer.getString(),
		].join('\n');
	}

}

export abstract class Record extends RecordOrSegment {

	get length() {
		return 426;
	}

	/**
	 * Contains a value equal to the length of the physical records.
	 * This value includes the four bytes reserved for this field.
	 * Identify the length of the record i.e. 426 character format would mean value in this field is 426.
	 * If fixed-length records are being reported, the record descriptor should be the same for each record.
	 * If variable, the record descriptor will change depending on the size of the record.
	 */
	@Field(0, [ 1, 4 ])
	recordDescriptor: number = this.length;

}

/**
 * The header record for Metro2 format
 */
export class Header extends Record {

	@Field(2, [ 5, 10 ], { required })
	recordIdentifier: string = 'HEADER';

	@Field(3, [ 11, 12 ])
	cycleNumber: string;

	programIDs: ProgramIDs = new ProgramIDs();

	@Field(8, [ 48, 55 ], { required })
	activityDate: Date;

	/**
	 * Report “today’s date” or ‘date of file creation’
	 */
	@Field(9, [ 56, 63 ], { required })
	dateCreated: Date = new Date();

	@Field(10, [ 64, 71 ])
	programDate: Date;

	@Field(11, [ 72, 79 ])
	programRevisionDate: Date;

	reporter: Reporter = new Reporter();

	softwareVendor: SoftwareVendor = new SoftwareVendor();

	/**
	 * Contains a unique identification number assigned to this reporting agency
	 */
	@Field(17, [ 271, 280 ])
	microBiltProgID: string;

}

export class ProgramIDs {

	@Field(4, [ 13, 22 ])
	innovis: string;

	@Field(5, [ 23, 32 ])
	equifax: string;

	@Field(6, [ 33, 37 ])
	experian: string;

	@Field(7, [ 38, 47 ])
	transUnion: string;

}

class Reporter {

	/**
	 * Report Name of the processing company sending the data.
	 * If the processing company sending the data is not the owner of the accounts,
	 * then this field should contain the owner of the accounts on the file.
	 */
	@Field(12, [ 80, 119 ], { required })
	name: string;

	@Field(13, [ 120, 215 ])
	address: string;

	@Field(14, [ 216, 225 ])
	phone: string;

}

class SoftwareVendor {

	/**
	 * If using a software vendor, or processor, provide name of processor.
	 * This field should be used by 3rd part vendors reporting data for someone else.
	 * (Usually not the owner of the accounts on the file).
	 */
	@Field(15, [ 226, 265 ])
	name: string;

	@Field(16, [ 266, 270 ])
	version: string;

}

@Mixin(JSONable)
export class Person {

	/**
	 * Report the last name of the primary consumer.
	 * For compound names report the two surnames with a hyphen.
	 * If name is greater than 25- truncate at 25 bytes.
	 */
	@Field(30, [ 232, 256 ], { required, deburr : true })
	last: string;

	/**
	 * Report the first name of the primary consumer.
	 * If reporting compound first names, use a hyphen to separate the two words.
	 * Full first name is required and not just the initial.
	 */
	@Field(31, [ 257, 276 ], { required, deburr : true })
	first: string;

	/**
	 * Report the full middle name of the primary consumer.
	 * If reporting compound middle names, use a hyphen to separate the two words. Full middle name should be provided.
	 */
	@Field(32, [ 277, 296 ], { deburr : true })
	middle: string;

	@Field(33, [ 297, 297 ])
	generation: Generation;

	/**
	 * Report the social insurance number of the primary consumer.
	 * This must be unique to the consumer being reported in the base segment.
	 * Reporting of this information greatly enhances accuracy of matching to the correct consumer.
	 * Do not enter space or hyphens.
	 */
	@Field(34, [ 298, 306 ])
	nationalID: string = '0'.repeat(9);

	/**
	 * Report the date of birth of the primary consumer.
	 * This must be unique to the consumer being reported in the base segment.
	 * If not available zero fill. If day is not available report 00.
	 */
	@Field(35, [ 307, 314 ])
	dateOfBirth: Date;

}

@Mixin(JSONable)
export class Address {

	@Field(39, [ 328, 329 ], { required })
	countryCode: string;

	@Field(40, [ 330, 361 ], { required, deburr : true })
	addressLine1: string;

	@Field(41, [ 362, 393 ])
	addressLine2: string;

	@Field(42, [ 394, 413 ], { required, deburr : true })
	city: string;

	@Field(43, [ 414, 415 ], { required })
	region: string;

	@Field(44, [ 416, 424 ], { required })
	postalCode: string;

	/**
	 * This field is used to determine if the address is that of the primary consumer.
	 * The values will not determine the use of the address to update the file.
	 * If not known, blank fill.
	 */
	@Field(45, [ 425, 425 ])
	addressIndicator: AddressIndicator;

}

/**
 * The base segment record for Metro2
 */
@Mixin(JSONable)
export class BaseSegment extends Record {

	@Field(2, [ 5, 5 ], { required })
	processingIndicator: number = 1;

	/**
	 * Enter the data and time of actual account information update using eastern standard time.
	 */
	@Field(3, [ 6, 19 ])
	timestamp: Date;

	/**
	 * Used to replace the most recently reported update for the same reporting time period.
	 */
	@Field(4, [ 20, 20 ], { required })
	correctionIndicator: CorrectionIndicator;

	/**
	 * This field should be used to identify the different branches/transits as well as the Institution code.
	 * The first 3 bytes of the field should be the institution code followed by the transit number.
	 * This field must not change from month to month without using the L1 segment.
	 */
	@Field(5, [ 21, 40 ])
	identificationNumber: string;

	@Field(6, [ 41, 42 ])
	cycleIdentifier: string;

	/**
	 * Account number cannot contain embedded spaces or any special characters. Account number is alpha-numeric.
	 * Note: both EquifaxUS and EquifaxCA do not actually process all 30 characters (only ~15)
	 */
	@Field(7, [ 43, 72 ], { required, validation : /^[A-Z0-9 ]*$/ })
	consumerAccountNumber: string;

	@Field(8, [ 73, 73 ], { required })
	portfolioType: PortfolioType;

	@Field(9, [ 74, 75 ], { required })
	accountType: AccountType;

	@Field(10, [ 76, 83 ], { required })
	dateOpened: Date;

	@Field(11, [ 84, 92 ], { required })
	creditLimit: number = 0;

	@Field(12, [ 93, 101 ], { required })
	highestCreditAmount: Amount;

	/**
	 * Report the duration of the loan if reporting mortgages or installment loans
	 */
	@Field(13, [ 102, 104 ])
	termsDuration: string;

	/**
	 * Report the frequency for payments due. These will be reported with a descriptive value on the database.
	 */
	@Field(14, [ 105, 105 ], { required })
	termsFrequency: TermsFrequency;

	@Field(15, [ 106, 114 ], { required })
	scheduledPaymentAmount: Amount;

	@Field(16, [ 115, 123 ], { required })
	actualPaymentAmount: Amount;

	/**
	 * The account status is used to report the current condition of the account.
	 * The Payment Rating which follows will only be used to report if the account is in collection or charge off status.
	 */
	@Field('17A', [ 124, 125 ], { required })
	accountStatus: AccountStatus;

	get isAccountClosed() {
		return [ AccountStatus.PaidOrClosedAccount, AccountStatus.ClosedCollectionsAccount, AccountStatus.DeleteEntireAccount ].includes(this.accountStatus)
			|| this.specialComment === SpecialComment.AccountClosed;
	}

	/**
	 * Equifax determines the rating of the account based on the account status in field 17A.
	 * Please note that Equifax does not use this field to rate accounts unless the account status is 05 or 13 and the status is delinquent
	 */
	@Field('17B', [ 126, 126 ])
	paymentRating: PaymentRating;

	@Field(18, [ 127, 150 ], {
		validation : function(this: BaseSegment) {
			return _.isNil(this.paymentHistoryProfile) || this.paymentHistoryProfile === ''
			    // value must be either all values from PaymentHistoryRating or nothing (in which it must be all spaces)
			    || (new RegExp(`^[${Object.values(PaymentHistoryRating)}]{24}| {24}$`)).test(this.paymentHistoryProfile);
		},
	})
	paymentHistoryProfile: string;

	@Field(19, [ 151, 152 ])
	specialComment: SpecialComment;

	@Field(20, [ 153, 154 ])
	complianceConditionCode: ComplianceConditionCode;

	@Field(21, [ 155, 163 ], { required })
	currentBalance: Amount;

	@Field(22, [ 164, 172 ], {
		required,
		validation : function(this: BaseSegment) {
			if (this.isAccountOwing && (!this.amountPastDue || this.amountPastDue.valueOf() === 0)) {
				throw new Error(`amountPastDue is required when accountStatus is: ${this.accountStatus}`);
			}
			return true;
		},
	})
	amountPastDue: Amount;

	/**
	 * If account status, Field 17A, equals 64 or 97, report the Original charge-off amount to loss, regardless of the declining balance.
	 * If payments are received, report the outstanding balance in the current balance and amount past due field.
	 */
	@Field(23, [ 173, 181 ])
	originalChargeOffAmount: Amount;

	/**
	 * Enter the date for the cycle being reported, or if non cycle the date which reflects the status being reported.
	 */
	@Field(24, [ 182, 189 ])
	dateOfAccountInformation: Date;

	/**
	 * If account is not current, report the date of the first delinquency that led to the account status of being delinquent.
	 * If the account becomes current, the date of first delinquency should be zero filled.
	 * Then if the account goes delinquent again, the Date of First Delinquency starts over with the new date of first delinquency.
	 * This is a required field for all accounts that are bad debt.
	 */
	@Field(25, [ 190, 197 ], {
		validation : function(this: BaseSegment) {
			if (this.isAccountOwing && !this.dateOfFirstDelinquency) {
				throw new Error(`dateOfFirstDelinquency is required when accountStatus is: ${this.accountStatus}`);
			}
			return true;
		},
	})
	dateOfFirstDelinquency: Date;

	/**
	 * Report the date the account was closed or paid in full.
	 */
	@Field(26, [ 198, 205 ], {
		required : function(this: BaseSegment) {
			return this.isAccountClosed;
		},
	})
	dateClosed: Date;

	/**
	 * Report the date of most recent payment.
	 */
	@Field(27, [ 206, 213 ])
	dateOfLastPayment: Date;

	@Field(28, [ 214, 230 ])
	currencyTypeCode: CurrencyTypeCode | string;

	@Field(29, [ 231, 231 ])
	consumerTransactionType: ConsumerTransactionType;

	@Field()
	person: Person = new Person();

	/**
	 * Report the home telephone number of the primary consumer. (area code+7 digit telephone number).
	 * If not available zero fill.
	 */
	@Field(36, [ 315, 324 ], { validation : /^\d{10}$/ })
	protected telephoneNumberValue: string = '0'.repeat(10);

	get telephoneNumber() {
		return this.telephoneNumberValue;
	}
	set telephoneNumber(newValue: string) {
		if (!newValue) {
			this.telephoneNumberValue = '0'.repeat(10);
			return;
		}
		this.telephoneNumberValue = newValue
			.replace(/[^\d]/g, '')	// get rid of any non-digit characters
			.replace(/^1/, '')		// get rid of any leading 1 (which is the calling code for North America)
			.substring(0, 10)		// take the first 10 digits at most
		;
	}

	@Field(37, [ 325, 325 ])
	associationCode: string;

	@Field()
	address: Address = new Address();

	@Field(46, [ 426, 426 ])
	residenceCode: ResidenceCode;

	get isAccountOwing() {
		return [
			AccountStatus.PastDue30Days,
			AccountStatus.PastDue60Days,
			AccountStatus.PastDue90Days,
			AccountStatus.PastDue120Days,
			AccountStatus.PastDue150Days,
			AccountStatus.PastDue180Days,
			AccountStatus.SeriouslyPastDue,
		].includes(this.accountStatus);
	}

}

@Mixin(JSONable)
export class AccountNumberChange extends RecordOrSegment {

	get length() {
		return 54;
	}

	@Field(1, [ 1, 2 ], { required })
	segmentIdentifier: string = 'L1';

	@Field(2, [ 3, 3 ], { required })
	changeIndicator: ChangeIndicator;

	@Field(3, [ 4, 33 ])
	newConsumerAccountNumber: string;

	@Field(4, [ 34, 53 ])
	newIdentifierNumber: string;

	setNewConsumerAccountNumber(newConsumerAccountNumber: string) {
		this.newConsumerAccountNumber = newConsumerAccountNumber;
		this.getString();	// sets this.changeIndicator
	}

	getString() {
		// automatically set the changeIndicator if not already set
		if (!this.changeIndicator) {
			if (this.newConsumerAccountNumber && !this.newIdentifierNumber) {
				this.changeIndicator = ChangeIndicator.AccountNumberChangeOnly;
			}
			else if (this.newIdentifierNumber && !this.newConsumerAccountNumber) {
				this.changeIndicator = ChangeIndicator.IdentificationChangeOnly;
			}
			else if (this.newIdentifierNumber && this.newConsumerAccountNumber) {
				this.changeIndicator = ChangeIndicator.AccountAndIdentificationChange;
			}
			else {
				throw new Error('new consumer of new identifier needed');
			}
		}

		return super.getString();
	}

}

@Mixin(JSONable)
export class OriginalCreditorName extends RecordOrSegment {

	get length() {
		return 34;
	}

	@Field(1, [ 1, 2 ], { required })
	segmentIdentifier: string = 'K1';

	@Field(2, [ 3, 32 ], { required })
	name: string;

	@Field(3, [ 33, 34 ])
	creditorClassification: number = 9; // rental/leasing

	constructor(name?: string) {
		super();
		if (name) {
			this.name = name;
		}
	}

}

@Mixin(JSONable)
export class Metro2Segment {

	@Field()
	base: BaseSegment;

	@Field()
	L1: AccountNumberChange; // eslint-disable-line @typescript-eslint/naming-convention

	@Field()
	K1: OriginalCreditorName; // eslint-disable-line @typescript-eslint/naming-convention

	constructor(baseSegment?: BaseSegment) {
		if (baseSegment) {
			this.base = baseSegment;
		}
	}

	getString() {
		this.base.recordDescriptor = this.base.length + (this.L1?.length ?? 0) + (this.K1?.length ?? 0);
		return this.base.getString() + (this.L1?.getString() ?? '') + (this.K1?.getString() ?? '');
	}

}

/**
 * The trailer record for Metro2
 */
export class Trailer extends Record {

	@Field(2, [ 5, 11 ], { required })
	recordIdentifier: string = 'TRAILER';

	@Field(3, [ 12, 20 ])
	totalBaseRecords: number;

	@Field(5, [ 30, 38 ])
	totalStatusCodeDF: number;

	@Field(6, [ 39, 47 ])
	totalsConsumerSegmentsJ1: number = 0;

	@Field(7, [ 48, 56 ])
	totalsConsumerSegmentsJ2: number = 0;

	@Field(8, [ 57, 65 ])
	blockCount: number;

	totalStatusCode = new TotalStatusCodes();

	@Field(31, [ 264, 362 ])
	totalSegments1: number = 0;

	totalsDateOfBirth: TotalsDateOfBirth = new TotalsDateOfBirth();

	@Field(46, [ 399, 407 ])
	totalTelephoneNumberSegments: number = 0;

	setTotals(baseSegments: Metro2Segment[]) {
		this.totalBaseRecords = baseSegments.length;

		// set all of the accountStatus totals
		_.forEach(AccountStatus, accountStatus => {
			const property = `code${accountStatus}`;
			if (this.totalStatusCode.hasOwnProperty(property)) {
				this.totalStatusCode[property] = baseSegments.filter(segment => segment.base.accountStatus === accountStatus).length;
			}
		});

		this.totalTelephoneNumberSegments   = baseSegments.filter(segment => !!segment.base.telephoneNumber).length;
		this.totalsDateOfBirth.baseSegments = baseSegments.filter(segment => segment.base.person.dateOfBirth instanceof Date).length;
		this.totalsDateOfBirth.allSegments  = this.totalsDateOfBirth.baseSegments;
	}

}

class TotalsDateOfBirth {

	@Field(42, [ 363, 371 ])
	allSegments: number = 0;

	@Field(43, [ 372, 380 ])
	baseSegments: number = 0;

	@Field(44, [ 381, 389 ])
	J1Segments: number = 0;		// eslint-disable-line @typescript-eslint/naming-convention

	@Field(45, [ 390, 398 ])
	J2Segments: number = 0;		// eslint-disable-line @typescript-eslint/naming-convention

}

class TotalStatusCodes {

	@Field(9, [ 66, 74 ])
	codeDA: number = 0;

	@Field(10, [ 75, 83 ])
	code05: number = 0;

	@Field(11, [ 84, 92 ])
	code11: number = 0;

	@Field(12, [ 93, 101 ])
	code13: number = 0;

	@Field(13, [ 102, 146 ])
	code61to65: number = 0;

	@Field(18, [ 147, 155 ])
	code71: number = 0;

	@Field(19, [ 156, 164 ])
	code78: number = 0;

	@Field(20, [ 165, 173 ])
	code80: number = 0;

	@Field(21, [ 174, 182 ])
	code82: number = 0;

	@Field(22, [ 183, 191 ])
	code83: number = 0;

	@Field(23, [ 192, 200 ])
	code84: number = 0;

	@Field(24, [ 201, 209 ])
	code88: number = 0;

	@Field(25, [ 210, 218 ])
	code89: number = 0;

	@Field(26, [ 219, 227 ])
	code93: number = 0;

	@Field(27, [ 228, 263 ])
	code94to97: number = 0;

}

export enum CorrectionIndicator {
	NormalUpdate      = '0',
	ReplacementUpdate = '1',
}

export enum PortfolioType {
	Installment  = 'I',
	Revolving    = 'R',
	LineOfCredit = 'C',
	Lease        = 'L',
	Mortgage     = 'M',
	Open         = 'O',
}

export enum AccountType  {
	Lease              = '13',
	RealEstateMortgage = '25',
	RentalAgreement    = '29',
	CollectionAgency   = '48',
}

export enum SpecialComment {
	TransferredToRecovery = 'BA',
	FullTermination       = 'BC',
	AccountClosed         = 'L',
}
export enum ComplianceConditionCode {
	DisputedByConsumer = 'XB',
	RemoveDispute      = 'XR',
}

export enum TermsFrequency {
	SinglePayment = 'P',
	Monthly       = 'M',
	Quarterly     = 'Q',
	Weekly        = 'W',
	Annually      = 'Y',
}

export enum AccountStatus {
	DeleteEntireAccount      = 'DA',
	AccountInGoodStanding    = '11',
	PaidOrClosedAccount      = '13',
	ClosedCollectionsAccount = '62',
	PastDue30Days            = '71',
	PastDue60Days            = '78',
	PastDue90Days            = '80',
	PastDue120Days           = '82',
	PastDue150Days           = '83',
	PastDue180Days           = '84',
	SeriouslyPastDue         = '93',
}

export enum PaymentRating {
	Collection           = 'G',
	ForeclosureCompleted = 'H',
	VoluntarySurrender   = 'J',
	Repossession         = 'K',
	ChargeOff            = 'L',
	CurrentAccount       = '0',		//  0 - 29 days past the due date
	PastDue30            = '1',		// 30 - 59 days past the due date
	PastDue60            = '2',		// 60 - 89 days past the due date
	PastDue90            = '3',		// 90 - 119 days past the due date
	PastDue120           = '4',		// 120 - 149 days past the due date
	PastDue150           = '5',		// 150 - 179 days past the due date
	PastDue180           = '6',		// 180 days or more past the due date
}

export enum PaymentHistoryRating {
	NoHistoryPrior       = 'B',		// No payment history available prior to this time – either because the account was not open or because the payment history cannot be furnished.  May not be embedded within other values.
	NoHistoryForMonth    = 'D',		// No payment history available this month. May be embedded in the payment pattern.
	CurrentAccount       = '0',		//  0 - 29 days past the due date
	PastDue30            = '1',		// 30 - 59 days past the due date
	PastDue60            = '2',		// 60 - 89 days past the due date
	PastDue90            = '3',		// 90 - 119 days past the due date
	PastDue120           = '4',		// 120 - 149 days past the due date
	PastDue150           = '5',		// 150 - 179 days past the due date
	PastDue180           = '6',		// 180 days or more past the due date
}

export enum ResidenceCode {
	Owns  = 'O',
	Rents = 'R',
}

export enum AddressIndicator {
	confirmedOrVerifiedAddress        = 'C',
	knownToBeAddressOfPrimaryConsumer = 'Y',
	notConfirmedAddress               = 'N',
	militaryAddress                   = 'M',
	secondaryAddress                  = 'S',
	businessAddress                   = 'B',
	returnedMail                      = 'U',
	defaultAddress                    = 'D',
	billPayerService                  = 'P',
}

export enum ConsumerTransactionType {
	NewAccount           = '1',
	NameChange           = '2',
	AddressChange        = '3',
	SINChange            = '5',
	NameAddressChange    = '6',
	NameSINChange        = '8',
	AddressSINChange     = '9',
	NameAddressSINChange = 'A',
}

export enum Generation {
	JR   = 'J',
	SR   = 'S',
	I    = '1',
	II   = '2',
	III  = '3',
	IV   = '4',
	V    = '5',
	VI   = '6',
	VII  = '7',
	VIII = '8',
	IX   = '9',
}

export enum AssociationCode {
	Individual = '1',
	Joint      = '2',
}


export enum CurrencyTypeCode {
	CA = '124',
	US = '998',
}

export enum CountryCode {
	CA = 'CN',
	US = 'US',
}

export enum ChangeIndicator {
	AccountNumberChangeOnly        = 1,
	IdentificationChangeOnly       = 2,
	AccountAndIdentificationChange = 3,
}
