import Axios  from 'axios';
import Moment from 'moment';

import { Entity } from '$/entities/BaseEntity';

import { TenantMetro2 as TenantMetro2Common, CreditBureau } from '$/common/entities/reporting/TenantMetro2';
export *                                                    from '$/common/entities/reporting/TenantMetro2';

@Entity()
export class TenantMetro2 extends TenantMetro2Common {

	/**
	 * @param month the month for which to get the status (YYYY-MM format)
	 */
	static async getStatus(month: string = Moment().subtract(1, 'month').format('YYYY-MM')) {
		return (await Axios.get(TenantMetro2.collectionUrl(`/status/${month}`))).data;
	}

	/**
	 * @param month the month for which to trigger processing (YYYY-MM format)
	 */
	static async triggerProcessing(month: string, { reset = false } = {}) {
		await Axios.post(TenantMetro2.collectionUrl(`/processing/${month}`), { reset });
	}

	/**
	 * @param month the month to fetch reports for (YYYY-MM format); defaults to last month
	 */
	static async getMetro2Reports(creditBureau: CreditBureau, { month = null }: { month?: string } = {}): Promise<Dictionary<string>> {
		month ??= Moment().startOf('month').subtract(1, 'month').format('YYYY-MM');
		return (await Axios.get(TenantMetro2.collectionUrl(`/report/${month}/${creditBureau}`))).data;
	}

	static async getAccountStatusRequirements() {
		return (await Axios.get(TenantMetro2.collectionUrl('/requirements'))).data;
	}

	/**
	 * Mark the Metro2 records of the given month to be set as uploaded.
	 * @param month Accepts a month in YYYY-MM format
	 */
	static async markAsUploaded(month: string) {
		await Axios.post(TenantMetro2.collectionUrl('/uploaded'), { month });
	}

}
